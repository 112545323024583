import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';
import Container from './Container';
import Button from './Button';

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex[40]};
  background-color: ${({ scrolled, theme }) => 
    scrolled ? theme.colors.background.primary : 'transparent'};
  box-shadow: ${({ scrolled, theme }) => 
    scrolled ? theme.shadows.md : 'none'};
  transition: all ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  height: 80px;
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const Logo = styled(Link)`
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  font-size: ${({ theme }) => theme.typography.fontSize['2xl']};
  color: ${({ scrolled, path }) => 
    scrolled ? '#1A1A2E' : (path === '/' ? '#1A1A2E' : '#fff')};
  text-decoration: none;
  transition: color ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary.light};
  }
`;

const NavItems = styled.nav`
  display: flex;
  align-items: center;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  margin: 0 ${({ theme }) => theme.spacing[4]};
  color: ${({ scrolled, path }) => 
    scrolled ? '#1A1A2E' : (path === '/' ? '#1A1A2E' : 'white')};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  text-decoration: none;
  position: relative;
  transition: color ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  
  &:hover, &.active {
    color: ${({ theme }) => theme.colors.primary.light};
    
    &::after {
      width: 100%;
    }
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary.light};
    transition: width ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  }
`;

const CTAButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing[4]};
  background: ${({ theme }) => theme.colors.primary.main};
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  transition: background 0.3s ease;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ scrolled, path }) => 
    scrolled ? '#1A1A2E' : (path === '/' ? '#1A1A2E' : 'white')};
  font-size: 24px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MobileMenuOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background.primary};
  z-index: ${({ theme }) => theme.zIndex[50]};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[4]};
`;

const MobileMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const MobileNavItems = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const MobileNavLink = styled(Link)`
  margin: ${({ theme }) => theme.spacing[3]} 0;
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  text-decoration: none;
  position: relative;
  transition: color ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  
  &:hover, &.active {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const MobileCTAButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing[6]};
  width: 100%;
  background: ${({ theme }) => theme.colors.primary.main};
  color: white;
  padding: 12px 20px;
  border-radius: 6px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  transition: background 0.3s ease;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
  }
`;

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  
  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // Prevent body scroll when menu is open
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [menuOpen]);
  
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  
  return (
    <>
      <HeaderWrapper scrolled={scrolled}>
        <HeaderContainer>
          <Logo to="/" scrolled={scrolled} path={currentPath}>
            BetterMarkt
          </Logo>
          
          <NavItems>
            <NavLink to="/" scrolled={scrolled} path={currentPath}>
              Home
            </NavLink>
            <NavLink to="/services" scrolled={scrolled} path={currentPath}>
              Services
            </NavLink>
            <NavLink to="/pricing" scrolled={scrolled} path={currentPath}>
              Pricing
            </NavLink>
            <CTAButton as={Link} to="/contact" $variant="primary" $size="md">
              Get Started
            </CTAButton>
          </NavItems>
          
          <MobileMenuButton onClick={toggleMenu} scrolled={scrolled} path={currentPath}>
            <FiMenu />
          </MobileMenuButton>
        </HeaderContainer>
      </HeaderWrapper>
      
      <AnimatePresence>
        {menuOpen && (
          <MobileMenuOverlay
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <MobileMenuHeader>
              <Logo to="/" scrolled={true} path={currentPath}>
                BetterMarkt
              </Logo>
              <MobileMenuButton onClick={toggleMenu} scrolled={true} path={currentPath}>
                <FiX />
              </MobileMenuButton>
            </MobileMenuHeader>
            
            <MobileNavItems>
              <MobileNavLink to="/" onClick={() => setMenuOpen(false)}>
                Home
              </MobileNavLink>
              <MobileNavLink to="/services" onClick={() => setMenuOpen(false)}>
                Services
              </MobileNavLink>
              <MobileNavLink to="/pricing" onClick={() => setMenuOpen(false)}>
                Pricing
              </MobileNavLink>
              
              <MobileCTAButton 
                as={Link} 
                to="/contact"
                onClick={() => setMenuOpen(false)}
                $variant="primary"
                $size="lg"
              >
                Get Started
              </MobileCTAButton>
            </MobileNavItems>
          </MobileMenuOverlay>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header; 