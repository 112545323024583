import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiArrowRight, FiCheck, FiBarChart2, FiAward, FiTrendingUp, FiDollarSign, FiClock, FiTarget } from 'react-icons/fi';
import VectorIllustration from '../components/VectorIllustration';
import { Container, Section, Button } from '../components/UIComponents';

// Animation variants for framer-motion
const fadeUpVariant = {
  hidden: { opacity: 0, y: 30 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.6, ease: 'easeOut' }
  }
};

const scaleVariant = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { duration: 0.6, ease: 'easeOut' }
  }
};

// Float animation using keyframes
const floatAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

// Styled components with clean design
const HeroSection = styled(Section)`
  padding: 120px 0 80px;
  background: linear-gradient(135deg, #f8f9ff 0%, #ffffff 100%);
  position: relative;

  @media (max-width: 768px) {
    padding: 100px 0 60px;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 992px) {
    flex-direction: column;
    text-align: center;
  }
`;

const HeroText = styled.div`
  flex: 1;
  max-width: 600px;

  @media (max-width: 992px) {
    max-width: 100%;
  }
`;

const HeroHighlight = styled.span`
  color: #3A0CA3;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: #4CC9F0;
    z-index: -1;
    opacity: 0.5;
  }
`;

const HeroTitle = styled(motion.h1)`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  line-height: 1.1;
  color: #1A1A2E;
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const HeroSubtitle = styled(motion.p)`
  font-size: 1.25rem;
  margin-bottom: 30px;
  color: #4A4A68;
  line-height: 1.6;
`;

const HeroImageWrapper = styled(motion.div)`
  flex: 1;
  max-width: 560px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  
  @media (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  
  @media (max-width: 992px) {
    justify-content: center;
  }
  
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.75rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
  color: #1A1A2E;

  @media (max-width: 768px) {
    font-size: 2.25rem;
  }
`;

const SectionSubtitle = styled.p`
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 60px;
  color: #4A4A68;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

const FeatureSection = styled(Section)`
  padding: 100px 0;
  background: #f8f9ff;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 60px;
  
  @media (max-width: 768px) {
    gap: 24px;
  }
`;

const FeatureCard = styled(motion.div)`
  background: white;
  border-radius: 16px;
  padding: 40px 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: #3A0CA3;
    border-radius: 16px 16px 0 0;
  }
`;

const FeatureIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-color: rgba(76, 201, 240, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  font-size: 24px;
  color: #3A0CA3;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 16px;
  color: #1A1A2E;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #4A4A68;
  line-height: 1.6;
  margin-bottom: 24px;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 24px 0;
`;

const FeatureItem = styled.li`
  padding: 8px 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #4A4A68;
  
  svg {
    color: #4ADE80;
    margin-right: 12px;
    flex-shrink: 0;
  }
`;

const HowItWorksSection = styled(Section)`
  padding: 100px 0;
  background: white;
  position: relative;
`;

const StepCard = styled.div`
  background: white;
  border-radius: 16px;
  padding: 40px 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
  }
`;

const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 60px;
  
  @media (max-width: 768px) {
    gap: 24px;
  }
  
  & > div {
    height: 100%;
  }
`;

const StepNumber = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: #3A0CA3;
  margin-bottom: 16px;
  display: inline-block;
  padding: 5px 12px;
  border-radius: 20px;
  background-color: rgba(76, 201, 240, 0.1);
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 16px;
  color: #1A1A2E;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #4A4A68;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const StepImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  margin-top: auto;
  border-radius: 8px;
  overflow: hidden;
`;

const CTASection = styled(Section)`
  padding: 80px 0;
  background: linear-gradient(135deg, #3A0CA3 0%, #4361EE 100%);
  color: white;
  text-align: center;
  position: relative;
`;

const CTATitle = styled.h2`
  font-size: 2.75rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: white;
  
  @media (max-width: 768px) {
    font-size: 2.25rem;
  }
`;

const CTAText = styled.p`
  font-size: 1.25rem;
  margin-bottom: 32px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.9;
`;

// ROI Calculator Section
const ROISection = styled(Section)`
  padding: 100px 0;
  background: #f8f9ff;
  position: relative;
`;

const ROICalculatorWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-top: 40px;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const ROIInputsCard = styled(motion.div)`
  background: white;
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
  }
`;

const ROIInputLabel = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 12px;
  color: #1A1A2E;
`;

const ROISliderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const ROISlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background: #e0e0e0;
  outline: none;
  margin-right: 16px;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #3A0CA3;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 6px rgba(58, 12, 163, 0.2);
    
    &:hover {
      transform: scale(1.1);
    }
  }
  
  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #3A0CA3;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease;
    box-shadow: 0 2px 6px rgba(58, 12, 163, 0.2);
    
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const ROISliderValue = styled.div`
  min-width: 120px;
  font-weight: 600;
  color: #3A0CA3;
`;

const ROIResultsCard = styled(motion.div)`
  background: linear-gradient(135deg, #3A0CA3 0%, #4361EE 100%);
  border-radius: 16px;
  padding: 40px;
  color: white;
`;

const ROIResultTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 30px;
`;

const ROIResultsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ROIResultItem = styled.div`
  background-color: ${props => props.highlight ? 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 0.1)'};
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const ROIResultIcon = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${props => props.highlight ? 'rgba(255, 255, 255, 0.25)' : 'rgba(255, 255, 255, 0.15)'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
`;

const ROIResultValue = styled.div`
  font-size: ${props => props.highlight ? '1.8rem' : '1.5rem'};
  font-weight: 700;
  margin-bottom: 8px;
`;

const ROIResultLabel = styled.div`
  font-size: 0.95rem;
  opacity: ${props => props.highlight ? 1 : 0.8};
`;

const ROIDisclaimer = styled.div`
  font-size: 0.8rem;
  opacity: 0.7;
  margin-top: 30px;
  text-align: center;
  font-style: italic;
`;

// Main component
const Home = () => {
  // ROI Calculator state
  const [monthlyMarketingBudget, setMonthlyMarketingBudget] = useState(10000);
  const [marketingTeamSize, setMarketingTeamSize] = useState(5);
  const [averageHourlyRate, setAverageHourlyRate] = useState(40);
  
  // Calculate ROI metrics
  const calculateMetrics = () => {
    // Efficiency improvement: 85%
    const efficiencyImprovement = 0.85;
    
    // Cost reduction: 37%
    const costReduction = 0.37;
    
    // ROI improvement: 42%
    const roiImprovement = 0.42;
    
    // Monthly time savings (hours)
    const workHoursPerMonth = marketingTeamSize * 160; // 160 hours per month per employee
    const timeSavings = workHoursPerMonth * efficiencyImprovement;
    
    // Monthly cost savings
    const laborCostSavings = timeSavings * averageHourlyRate;
    const budgetSavings = monthlyMarketingBudget * costReduction;
    const totalMonthlySavings = laborCostSavings + budgetSavings;
    
    // Annual savings
    const annualSavings = totalMonthlySavings * 12;
    
    // Marketing performance improvement
    const performanceImprovement = monthlyMarketingBudget * roiImprovement;
    
    return {
      timeSavings: Math.round(timeSavings),
      laborCostSavings: Math.round(laborCostSavings),
      budgetSavings: Math.round(budgetSavings),
      totalMonthlySavings: Math.round(totalMonthlySavings),
      annualSavings: Math.round(annualSavings),
      performanceImprovement: Math.round(performanceImprovement)
    };
  };
  
  const metrics = calculateMetrics();
  
  return (
    <>
      <HeroSection>
        <Container>
          <HeroContent>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeUpVariant}
              >
                <HeroText>
                  <HeroTitle>
                  Transform Your Marketing with <HeroHighlight>AI-Powered</HeroHighlight> Solutions
                  </HeroTitle>
                  <HeroSubtitle>
                    BetterMarkt combines cutting-edge AI technology with marketing expertise to help businesses boost performance, optimize campaigns, and drive remarkable results.
                  </HeroSubtitle>
                  <ButtonGroup>
                    <Button as={Link} to="/services" $size="large">
                      Explore Services
                    </Button>
                    <Button as={Link} to="/contact" $size="large" $variant="outlined">
                      Get Started <FiArrowRight style={{ marginLeft: '8px' }} />
                    </Button>
                  </ButtonGroup>
                </HeroText>
              </motion.div>
            
            <motion.div
              initial="hidden"
              animate="visible"
              variants={scaleVariant}
            >
              <HeroImageWrapper>
                <VectorIllustration.MarketingDashboard 
                  width="100%" 
                  height="400px"
                  backgroundColor="#f8f9ff"
                  shadow={true}
                />
              </HeroImageWrapper>
            </motion.div>
          </HeroContent>
        </Container>
      </HeroSection>

      <FeatureSection>
        <Container>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeUpVariant}
          >
            <SectionTitle>AI-Powered Marketing Solutions</SectionTitle>
              <SectionSubtitle>
              We help businesses transform their marketing with advanced AI tools and automation
              </SectionSubtitle>
          </motion.div>

          <FeaturesGrid>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              variants={fadeUpVariant}
              transition={{ delay: 0.1 }}
            >
              <FeatureCard>
                <FeatureIconWrapper>
                  <FiBarChart2 />
                </FeatureIconWrapper>
                <FeatureTitle>Automated Content Creation</FeatureTitle>
                <FeatureDescription>
                  Generate high-quality marketing content across channels with our AI-powered tools.
                </FeatureDescription>
                <FeaturesList>
                  <FeatureItem>
                    <FiCheck /> Social media posts
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> Blog articles & newsletters
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> Ad copy & email campaigns
                  </FeatureItem>
                </FeaturesList>
              </FeatureCard>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              variants={fadeUpVariant}
              transition={{ delay: 0.2 }}
            >
              <FeatureCard>
                <FeatureIconWrapper>
                  <FiAward />
                </FeatureIconWrapper>
                <FeatureTitle>Smart Campaign Optimization</FeatureTitle>
                <FeatureDescription>
                  Optimize your marketing campaigns with real-time data analysis and AI recommendations.
                </FeatureDescription>
                <FeaturesList>
                  <FeatureItem>
                    <FiCheck /> Performance tracking
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> AI-driven improvements
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> Budget optimization
                  </FeatureItem>
                </FeaturesList>
              </FeatureCard>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              variants={fadeUpVariant}
              transition={{ delay: 0.3 }}
            >
              <FeatureCard>
                <FeatureIconWrapper>
                  <FiTrendingUp />
                </FeatureIconWrapper>
                <FeatureTitle>Audience Insights & Targeting</FeatureTitle>
                <FeatureDescription>
                  Leverage AI to understand your audience and create targeted marketing campaigns.
                </FeatureDescription>
                <FeaturesList>
                  <FeatureItem>
                    <FiCheck /> Customer segmentation
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> Behavioral analytics
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> Personalized messaging
                  </FeatureItem>
                </FeaturesList>
              </FeatureCard>
            </motion.div>
          </FeaturesGrid>
        </Container>
      </FeatureSection>

      <HowItWorksSection>
        <Container>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeUpVariant}
          >
              <SectionTitle>How It Works</SectionTitle>
              <SectionSubtitle>
              Our approach to AI-powered marketing follows a proven three-step process
              </SectionSubtitle>
          </motion.div>

          <StepsContainer>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              variants={fadeUpVariant}
              transition={{ delay: 0.1 }}
            >
              <StepCard>
                <StepNumber>Step 1</StepNumber>
                <StepTitle>Data Analysis</StepTitle>
                <StepDescription>
                  We collect and analyze your marketing data to identify trends, opportunities, and areas for improvement using our AI-powered platform.
                </StepDescription>
                <FeaturesList>
                  <FeatureItem>
                    <FiCheck /> Multi-channel data collection
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> Predictive analytics
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> Competitor benchmarking
                  </FeatureItem>
                </FeaturesList>
                <StepImageWrapper>
                  <VectorIllustration.AnalyticsIllustration 
                    width="100%" 
                    height="100%" 
                    backgroundColor="#f0f7ff"
                  />
                </StepImageWrapper>
              </StepCard>
            </motion.div>
            
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              variants={fadeUpVariant}
              transition={{ delay: 0.2 }}
            >
              <StepCard>
                <StepNumber>Step 2</StepNumber>
                <StepTitle>Strategy Implementation</StepTitle>
                <StepDescription>
                  Based on our analysis, we develop and implement customized marketing strategies designed to address your specific goals and challenges.
                </StepDescription>
                <FeaturesList>
                  <FeatureItem>
                    <FiCheck /> Personalized approach
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> AI-driven recommendations
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> Seamless execution
                  </FeatureItem>
                </FeaturesList>
                <StepImageWrapper>
                  <VectorIllustration.ImplementationIllustration 
                    width="100%" 
                    height="100%" 
                    backgroundColor="#f0fff7"
                  />
                </StepImageWrapper>
              </StepCard>
            </motion.div>
            
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              variants={fadeUpVariant}
              transition={{ delay: 0.3 }}
            >
              <StepCard>
                <StepNumber>Step 3</StepNumber>
                <StepTitle>Continuous Optimization</StepTitle>
                <StepDescription>
                  We continuously monitor performance and make data-driven adjustments to maximize ROI and ensure long-term success.
                </StepDescription>
                <FeaturesList>
                  <FeatureItem>
                    <FiCheck /> Real-time monitoring
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> Performance fine-tuning
                  </FeatureItem>
                  <FeatureItem>
                    <FiCheck /> Growth acceleration
                  </FeatureItem>
                </FeaturesList>
                <StepImageWrapper>
                  <VectorIllustration.OptimizationIllustration 
                    width="100%" 
                    height="100%" 
                    backgroundColor="#fff0f7"
                  />
                </StepImageWrapper>
              </StepCard>
            </motion.div>
          </StepsContainer>
        </Container>
      </HowItWorksSection>

      <ROISection>
        <Container>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeUpVariant}
          >
            <SectionTitle>Calculate Your Potential ROI</SectionTitle>
              <SectionSubtitle>
              See how much your business could save and gain with our AI-powered marketing solutions
              </SectionSubtitle>
          </motion.div>

    <ROICalculatorWrapper>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              variants={fadeUpVariant}
              transition={{ delay: 0.1 }}
            >
      <ROIInputsCard>
        <ROIInputLabel>Monthly Marketing Budget ($)</ROIInputLabel>
        <ROISliderContainer>
          <ROISlider 
            type="range" 
            min="5000" 
            max="100000" 
            step="1000" 
            value={monthlyMarketingBudget} 
            onChange={(e) => setMonthlyMarketingBudget(parseInt(e.target.value))} 
          />
          <ROISliderValue>${monthlyMarketingBudget.toLocaleString()}</ROISliderValue>
        </ROISliderContainer>
        
        <ROIInputLabel>Marketing Team Size</ROIInputLabel>
        <ROISliderContainer>
          <ROISlider 
            type="range" 
            min="1" 
            max="20" 
            step="1" 
            value={marketingTeamSize} 
            onChange={(e) => setMarketingTeamSize(parseInt(e.target.value))} 
          />
                  <ROISliderValue>{marketingTeamSize} {marketingTeamSize === 1 ? 'person' : 'people'}</ROISliderValue>
        </ROISliderContainer>
        
        <ROIInputLabel>Average Hourly Rate ($)</ROIInputLabel>
        <ROISliderContainer>
          <ROISlider 
            type="range" 
            min="20" 
            max="100" 
            step="5" 
            value={averageHourlyRate} 
            onChange={(e) => setAverageHourlyRate(parseInt(e.target.value))} 
          />
          <ROISliderValue>${averageHourlyRate}/hour</ROISliderValue>
        </ROISliderContainer>
      </ROIInputsCard>
            </motion.div>
            
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              variants={fadeUpVariant}
              transition={{ delay: 0.2 }}
            >
      <ROIResultsCard>
                <ROIResultTitle>Your Potential Results</ROIResultTitle>
        
        <ROIResultsGrid>
          <ROIResultItem>
            <ROIResultIcon>
              <FiClock size={24} />
            </ROIResultIcon>
            <ROIResultValue>{metrics.timeSavings.toLocaleString()}</ROIResultValue>
            <ROIResultLabel>Hours Saved Monthly</ROIResultLabel>
          </ROIResultItem>
          
          <ROIResultItem>
            <ROIResultIcon>
              <FiDollarSign size={24} />
            </ROIResultIcon>
            <ROIResultValue>${metrics.totalMonthlySavings.toLocaleString()}</ROIResultValue>
            <ROIResultLabel>Monthly Cost Savings</ROIResultLabel>
          </ROIResultItem>
          
          <ROIResultItem>
            <ROIResultIcon>
              <FiTarget size={24} />
            </ROIResultIcon>
            <ROIResultValue>${metrics.performanceImprovement.toLocaleString()}</ROIResultValue>
            <ROIResultLabel>Monthly ROI Improvement</ROIResultLabel>
          </ROIResultItem>
          
          <ROIResultItem highlight>
            <ROIResultIcon highlight>
              <FiDollarSign size={24} />
            </ROIResultIcon>
            <ROIResultValue highlight>${metrics.annualSavings.toLocaleString()}</ROIResultValue>
            <ROIResultLabel highlight>Total Annual Savings</ROIResultLabel>
          </ROIResultItem>
        </ROIResultsGrid>
        
        <ROIDisclaimer>
                  *Results are estimates based on industry averages. Your actual results may vary.
        </ROIDisclaimer>
      </ROIResultsCard>
            </motion.div>
    </ROICalculatorWrapper>
        </Container>
      </ROISection>

      <CTASection>
        <Container>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeUpVariant}
          >
            <CTATitle>Ready to Transform Your Marketing?</CTATitle>
            <CTAText>
              Get started today and discover how our AI-powered marketing solutions can help your business grow.
            </CTAText>
            <Button 
              as={Link} 
              to="/contact/sales" 
              $size="large"
              $variant="light"
            >
              Schedule a Demo
            </Button>
          </motion.div>
        </Container>
      </CTASection>
    </>
  );
};

export default Home;