import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Container from '../components/Container';
import { Link } from 'react-router-dom';

// Define fallback colors in case theme is not available
const fallbackColors = {
  primary: {
    main: '#3A0CA3',
    light: '#4CC9F0',
    dark: '#270980',
    contrast: '#FFFFFF'
  },
  text: {
    primary: '#1A1A2E',
    secondary: '#4A4A68',
    tertiary: '#6B7280',
    white: '#FFFFFF'
  },
  background: {
    default: '#FFFFFF',
    light: '#F8FAFC',
    dark: '#1A1A2E',
    card: '#FFFFFF',
    primary: '#FFFFFF',
    secondary: '#F8FAFC',
    tertiary: '#F1F5F9'
  },
  border: {
    light: '#E2E8F0'
  }
};

const HeaderSection = styled.section`
  padding: 120px 0 60px;
  background: linear-gradient(135deg, #3A0CA3 0%, #4361EE 100%);
  text-align: center;
  color: white;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
  }
`;

const HeaderTitle = styled.h1`
  color: white;
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 24px;
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 2.5rem;
  }
`;

const HeaderDescription = styled.p`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 720px;
  margin: 0 auto;
  line-height: 1.7;
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 1.1rem;
  }
`;

const ContentSection = styled.section`
  padding: 60px 0;
  background: ${({ theme }) => theme?.colors?.background?.primary || fallbackColors.background.primary};
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
`;

const SectionSubtitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  line-height: 1.7;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
`;

const List = styled.ul`
  margin-bottom: 20px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  line-height: 1.7;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
`;

const LastUpdated = styled.p`
  font-style: italic;
  color: ${({ theme }) => theme?.colors?.text?.tertiary || fallbackColors.text.tertiary};
  margin-top: 60px;
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
`;

const PolicyLink = styled(Link)`
  color: ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Terms = () => {
  return (
    <>
      <HeaderSection>
        <Container>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <HeaderTitle>Terms of Service</HeaderTitle>
            <HeaderDescription>
              Please read these Terms of Service carefully before using our services.
              They govern your use of BetterMarkt's website and services.
            </HeaderDescription>
          </motion.div>
        </Container>
      </HeaderSection>
      
      <ContentSection>
        <Container>
          <ContentContainer>
            <Paragraph>
              <strong>Effective Date: March 20, 2024</strong>
            </Paragraph>
            
            <Paragraph>
              These Terms of Service ("Terms") govern your access to and use of the services, including our website, APIs, email notifications, applications, and other covered services that link to these Terms (collectively, the "Services"), and any information, text, graphics, photos or other materials uploaded, downloaded or appearing on the Services (collectively referred to as "Content"). Your access to and use of the Services are conditioned on your acceptance of and compliance with these Terms. By accessing or using the Services you agree to be bound by these Terms.
            </Paragraph>
            
            <SectionTitle>1. Basic Terms</SectionTitle>
            
            <Paragraph>
              You are responsible for your use of the Services and for any Content you provide, including compliance with applicable laws, rules, and regulations. You should only provide Content that you are comfortable sharing with others.
            </Paragraph>
            
            <Paragraph>
              Any use or reliance on any Content or materials posted via the Services or obtained by you through the Services is at your own risk. We do not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any Content or communications posted via the Services or endorse any opinions expressed via the Services.
            </Paragraph>
            
            <Paragraph>
              You understand that by using the Services, you may be exposed to Content that might be offensive, harmful, inaccurate or otherwise inappropriate, or in some cases, postings that have been mislabeled or are otherwise deceptive. All Content is the sole responsibility of the person who originated such Content. We may not monitor or control the Content posted via the Services and, we cannot take responsibility for such Content.
            </Paragraph>
            
            <SectionTitle>2. Privacy</SectionTitle>
            
            <Paragraph>
              Our <PolicyLink to="/privacy">Privacy Policy</PolicyLink> describes how we handle the information you provide to us when you use our Services. You understand that through your use of the Services you consent to the collection and use (as set forth in the Privacy Policy) of this information, including the transfer of this information to the United States, Ireland, and/or other countries for storage, processing and use by BetterMarkt and its affiliates.
            </Paragraph>
            
            <SectionTitle>3. Account Security</SectionTitle>
            
            <Paragraph>
              You are responsible for safeguarding your account, so use a strong password and limit its use to this account. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above.
            </Paragraph>
            
            <SectionTitle>4. Services and Pricing</SectionTitle>
            
            <SectionSubtitle>4.1. Service Offerings</SectionSubtitle>
            <Paragraph>
              BetterMarkt offers AI-powered marketing automation services, including content creation, customer segmentation, social media management, email marketing, SEO optimization, and analytics. The specific features and capabilities available to you depend on the subscription tier you select.
            </Paragraph>
            
            <SectionSubtitle>4.2. Pricing and Payment Terms</SectionSubtitle>
            <Paragraph>
              Subscription fees are charged in advance on a monthly or annual basis, depending on the billing cycle you select when purchasing the Services. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not cancel your account, we may suspend your access to the Services until we have successfully charged a valid payment method.
            </Paragraph>
            
            <SectionSubtitle>4.3. Subscription Changes and Cancellation</SectionSubtitle>
            <Paragraph>
              You may upgrade, downgrade, or cancel your subscription at any time. If you downgrade your subscription, you may lose access to certain features, data, or capabilities of your account. If you cancel your subscription, you will continue to have access to the Services through the end of your current billing period, but you will not receive a refund for any fees already paid.
            </Paragraph>
            
            <SectionTitle>5. Content on the Services</SectionTitle>
            
            <SectionSubtitle>5.1. Your Rights</SectionSubtitle>
            <Paragraph>
              You retain your rights to any Content you submit, post or display on or through the Services. By submitting, posting or displaying Content on or through the Services, you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such Content in any and all media or distribution methods now known or later developed.
            </Paragraph>
            
            <SectionSubtitle>5.2. BetterMarkt Rights</SectionSubtitle>
            <Paragraph>
              All right, title, and interest in and to the Services (excluding Content provided by users) are and will remain the exclusive property of BetterMarkt and its licensors. The Services are protected by copyright, trademark, and other laws of both the United States and foreign countries. Nothing in the Terms gives you a right to use the BetterMarkt name or any of the BetterMarkt trademarks, logos, domain names, and other distinctive brand features.
            </Paragraph>
            
            <SectionTitle>6. Restrictions on Content and Use of the Services</SectionTitle>
            
            <Paragraph>
              We reserve the right at all times (but will not have an obligation) to remove or refuse to distribute any Content on the Services, to suspend or terminate users, and to reclaim usernames without liability to you. We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to:
            </Paragraph>
            
            <List>
              <ListItem>satisfy any applicable law, regulation, legal process or governmental request;</ListItem>
              <ListItem>enforce the Terms, including investigation of potential violations;</ListItem>
              <ListItem>detect, prevent, or otherwise address fraud, security or technical issues;</ListItem>
              <ListItem>respond to user support requests; or</ListItem>
              <ListItem>protect the rights, property or safety of BetterMarkt, its users and the public.</ListItem>
            </List>
            
            <SectionTitle>7. Ending These Terms</SectionTitle>
            
            <Paragraph>
              You may end your legal agreement with BetterMarkt at any time by deactivating your account and discontinuing your use of the Services. To deactivate your account, please contact us at contact@bettermarkt.com.
            </Paragraph>
            
            <Paragraph>
              We may suspend or terminate your account or cease providing you with all or part of the Services at any time for any or no reason, including, but not limited to, if we reasonably believe:
            </Paragraph>
            
            <List>
              <ListItem>you have violated these Terms;</ListItem>
              <ListItem>you create risk or possible legal exposure for us;</ListItem>
              <ListItem>your account should be removed due to unlawful conduct;</ListItem>
              <ListItem>your account should be removed due to prolonged inactivity; or</ListItem>
              <ListItem>our provision of the Services to you is no longer commercially viable.</ListItem>
            </List>
            
            <SectionTitle>8. Disclaimers and Limitations of Liability</SectionTitle>
            
            <Paragraph>
              The Services are provided "AS IS" and "AS AVAILABLE" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. BetterMarkt does not warrant that the Services will be uninterrupted, secure, or error-free.
            </Paragraph>
            
            <Paragraph>
              To the maximum extent permitted by applicable law, BetterMarkt shall not be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
            </Paragraph>
            
            <List>
              <ListItem>your access to or use of or inability to access or use the Services;</ListItem>
              <ListItem>any conduct or content of any third party on the Services;</ListItem>
              <ListItem>any content obtained from the Services; and</ListItem>
              <ListItem>unauthorized access, use or alteration of your transmissions or content.</ListItem>
            </List>
            
            <SectionTitle>9. General</SectionTitle>
            
            <Paragraph>
              These Terms shall be governed by the laws of the United Arab Emirates, without respect to its conflict of laws principles. The exclusive place of jurisdiction for all disputes arising from or in connection with this agreement is Dubai, United Arab Emirates.
            </Paragraph>
            
            <Paragraph>
              We may revise these Terms from time to time. The most current version will always be on this page. If the revision, in our sole discretion, is material we will notify you via email to the email associated with your account or through the Services. By continuing to access or use the Services after those revisions become effective, you agree to be bound by the revised Terms.
            </Paragraph>
            
            <Paragraph>
              These Terms are an agreement between you and BetterMarkt, Inc., Dubai, UAE. If you have any questions about these Terms, please contact us at contact@bettermarkt.com.
            </Paragraph>
            
            <LastUpdated>Last updated: March 20, 2024</LastUpdated>
          </ContentContainer>
        </Container>
      </ContentSection>
    </>
  );
};

export default Terms; 