import { useState, useEffect, useRef } from 'react';

/**
 * Custom hook to detect when an element is in the viewport
 * @param {Object} options - IntersectionObserver options
 * @param {number} options.threshold - A number between 0 and 1 indicating the percentage that should be visible
 * @param {string} options.rootMargin - Margin around the root
 * @param {boolean} options.once - Whether to unobserve the element after it becomes visible
 * @returns {Array} - [ref, isInView]
 */
export const useInView = (options = {}) => {
  const { threshold = 0.1, rootMargin = '0px', once = false } = options;
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);
  const observerRef = useRef(null);
  
  useEffect(() => {
    const callback = entries => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setIsInView(true);
        if (once && ref.current && observerRef.current) {
          observerRef.current.unobserve(ref.current);
        }
      } else if (!once) {
        setIsInView(false);
      }
    };

    const element = ref.current;
    observerRef.current = new IntersectionObserver(callback, {
      threshold,
      rootMargin
    });
    
    if (element) {
      observerRef.current.observe(element);
    }
    
    return () => {
      if (element && observerRef.current) {
        observerRef.current.unobserve(element);
      }
    };
  }, [threshold, rootMargin, once]);
  
  return [ref, isInView];
};

/**
 * Custom hook to get the current scroll position
 * @returns {Object} - { scrollX, scrollY }
 */
export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState({ scrollX: 0, scrollY: 0 });
  
  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition({
        scrollX: window.scrollX,
        scrollY: window.scrollY
      });
    };
    
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);
  
  return scrollPosition;
};

/**
 * Custom hook to track scroll progress within a specific element
 * @param {Object} options - Options for the hook
 * @param {RefObject} options.ref - React ref object for the target element
 * @returns {Object} - { scrollYProgress, scrollXProgress }
 */
export const useScrollProgress = (options = {}) => {
  const elementRef = useRef(null);
  const ref = options.ref || elementRef;
  
  const [progress, setProgress] = useState({
    scrollYProgress: 0,
    scrollXProgress: 0
  });
  
  useEffect(() => {
    const element = ref.current;
    if (!element) return;
    
    const updateProgress = () => {
      const { top, left, height, width } = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      
      // Calculate how much of the element is visible
      const visibleY = Math.min(windowHeight, top + height) - Math.max(0, top);
      const visibleX = Math.min(windowWidth, left + width) - Math.max(0, left);
      
      // Calculate progress as a ratio of visible portion
      const scrollYProgress = Math.min(Math.max(visibleY / height, 0), 1);
      const scrollXProgress = Math.min(Math.max(visibleX / width, 0), 1);
      
      setProgress({ scrollYProgress, scrollXProgress });
    };
    
    // Update on scroll and resize
    window.addEventListener('scroll', updateProgress);
    window.addEventListener('resize', updateProgress);
    updateProgress();
    
    return () => {
      window.removeEventListener('scroll', updateProgress);
      window.removeEventListener('resize', updateProgress);
    };
  }, [ref]);
  
  return progress;
};

/**
 * Custom hook to detect scroll direction
 * @returns {string} - 'up' or 'down'
 */
export const useScrollDirection = () => {
  const [direction, setDirection] = useState('up');
  const prevScrollY = useRef(0);
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > prevScrollY.current) {
        setDirection('down');
      } else {
        setDirection('up');
      }
      prevScrollY.current = currentScrollY;
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  return direction;
}; 