import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from '../utils/ScrollObserver';

const StyledSection = styled(motion.section)`
  position: relative;
`;

// Transition and animation variants
const defaultTransition = {
  duration: 0.7,
  ease: [0.43, 0.13, 0.23, 0.96]
};

const defaultAnimationVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: defaultTransition
  }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { ...defaultTransition, duration: 1.2 }
  }
};

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 75 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: defaultTransition
  }
};

const fadeInLeftVariants = {
  hidden: { opacity: 0, x: -75 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: defaultTransition
  }
};

const fadeInRightVariants = {
  hidden: { opacity: 0, x: 75 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: defaultTransition
  }
};

const scaleUpVariants = {
  hidden: { opacity: 0, scale: 0.85 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: defaultTransition
  }
};

const staggerChildrenVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { 
      staggerChildren: 0.1,
      delayChildren: 0.1,
      when: "beforeChildren"
    }
  }
};

const animations = {
  default: defaultAnimationVariants,
  fadeIn: fadeInVariants,
  fadeInUp: fadeInUpVariants,
  fadeInLeft: fadeInLeftVariants,
  fadeInRight: fadeInRightVariants,
  scaleUp: scaleUpVariants,
  staggerChildren: staggerChildrenVariants
};

const AnimatedSection = ({ 
  children, 
  animation = 'default', 
  delay = 0, 
  threshold = 0.15, 
  rootMargin = "0px 0px -100px 0px",
  as = 'section',
  className,
  style,
  ...props 
}) => {
  // Use the custom hook to detect when the section comes into view
  const [ref, isInView] = useInView({
    threshold,
    rootMargin,
    once: true
  });
  
  // Get the variant based on the animation prop
  const variant = animations[animation] || animations.default;
  
  return (
    <StyledSection
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={variant}
      className={className}
      style={style}
      as={as}
      {...props}
    >
      {typeof children === 'function' ? children(isInView) : children}
    </StyledSection>
  );
};

export const AnimatedElement = ({
  children,
  animation = 'default',
  delay = 0,
  className,
  style,
  as = 'div',
  ...props
}) => {
  // Get the variant based on the animation prop
  const variant = animations[animation] || animations.default;
  
  // Add delay to the transition
  const customVariant = {
    ...variant,
    visible: {
      ...variant.visible,
      transition: {
        ...variant.visible.transition,
        delay
      }
    }
  };
  
  return (
    <motion.div
      variants={customVariant}
      className={className}
      style={style}
      as={as}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedSection; 