import React from 'react';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  max-width: ${({ fluid }) => (fluid ? '100%' : '1200px')};
  margin: 0 auto;
  padding-left: ${({ theme }) => theme.spacing[4]};
  padding-right: ${({ theme }) => theme.spacing[4]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: ${({ theme }) => theme.spacing[3]};
    padding-right: ${({ theme }) => theme.spacing[3]};
  }
  
  ${({ narrow }) =>
    narrow &&
    css`
      max-width: 800px;
    `}
  
  ${({ wide }) =>
    wide &&
    css`
      max-width: 1400px;
    `}
`;

const Container = ({
  children,
  fluid = false,
  narrow = false,
  wide = false,
  as = 'div',
  className,
  ...props
}) => {
  return (
    <StyledContainer
      as={as}
      fluid={fluid}
      narrow={narrow}
      wide={wide}
      className={className}
      {...props}
    >
      {children}
    </StyledContainer>
  );
};

export default Container; 