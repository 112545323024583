import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Container from '../components/Container';
import Button from '../components/Button';

const UnsubscribeSection = styled.section`
  padding: 100px 0;
  min-height: 70vh;
  display: flex;
  align-items: center;
`;

const UnsubscribeCard = styled.div`
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  padding: 40px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 1.1rem;
  margin-bottom: 30px;
  line-height: 1.6;
`;

const StatusMessage = styled.div`
  margin: 24px 0;
  padding: 16px;
  border-radius: 8px;
  font-weight: 500;
  
  ${({ isSuccess, theme }) => isSuccess ? `
    background-color: rgba(16, 185, 129, 0.1);
    color: #10B981;
  ` : `
    background-color: rgba(239, 68, 68, 0.1);
    color: #EF4444;
  `}
`;

const Unsubscribe = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Get email from URL query parameter
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  const handleUnsubscribe = async () => {
    if (!email) {
      setStatus('error');
      setMessage('No email address provided.');
      return;
    }

    setIsSubmitting(true);
    setStatus('');
    setMessage('');

    try {
      const response = await fetch('https://n8n.bettermarkt.com/webhook/bdbc8e69-2b2e-48b1-bba8-0d80c1f721e2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          subscribedOn: new Date().toISOString(),
          status: 'unsubscribed'
        }),
      });

      if (response.ok) {
        setStatus('success');
        setMessage('You have been successfully unsubscribed from our newsletter.');
      } else {
        setStatus('error');
        setMessage('Something went wrong. Please try again later.');
      }
    } catch (error) {
      setStatus('error');
      setMessage('Network error. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <UnsubscribeSection>
      <Container>
        <UnsubscribeCard>
          <Title>Newsletter Unsubscribe</Title>
          <Description>
            {email ? 
              `We're sorry to see you go. Please confirm that you would like to unsubscribe ${email} from our newsletter.` : 
              'You need to provide an email address to unsubscribe. Usually you can access this page through the unsubscribe link in our emails.'}
          </Description>
          
          {status && (
            <StatusMessage isSuccess={status === 'success'}>
              {message}
            </StatusMessage>
          )}

          <Button 
            onClick={handleUnsubscribe} 
            disabled={isSubmitting || !email || status === 'success'}
          >
            {isSubmitting ? 'Processing...' : 'Unsubscribe'}
          </Button>
        </UnsubscribeCard>
      </Container>
    </UnsubscribeSection>
  );
};

export default Unsubscribe; 