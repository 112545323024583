import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiTwitter, FiLinkedin, FiFacebook, FiInstagram, FiArrowRight } from 'react-icons/fi';
import Container from './Container';
import Button from './Button';

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  padding: ${({ theme }) => `${theme.spacing[16]} 0 ${theme.spacing[8]}`};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => `${theme.spacing[12]} 0 ${theme.spacing[6]}`};
  }
`;

const FooterTop = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacing[8]};
  margin-bottom: ${({ theme }) => theme.spacing[12]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.spacing[6]};
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing[8]};
  }
`;

const FooterBrand = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column: span 2;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: span 1;
  }
`;

const Logo = styled(Link)`
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  font-size: ${({ theme }) => theme.typography.fontSize['2xl']};
  color: ${({ theme }) => theme.colors.primary.main};
  text-decoration: none;
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const FooterDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  max-width: 400px;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[3]};
`;

const SocialLink = styled.a`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 20px;
  transition: color ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const FooterColumn = styled.div``;

const FooterTitle = styled.h5`
  font-size: ${({ theme }) => theme.typography.fontSize.lg};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const FooterLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterLink = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  
  a {
    color: ${({ theme }) => theme.colors.text.secondary};
    text-decoration: none;
    transition: color ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
    font-size: ${({ theme }) => theme.typography.fontSize.md};
    
    &:hover {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }
`;

const NewsletterForm = styled.form`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const NewsletterInput = styled.input`
  flex: 1;
  padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[4]}`};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  font-size: ${({ theme }) => theme.typography.fontSize.md};
  outline: none;
  transition: border-color ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.spacing[2]};
  }
`;

const NewsletterButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing[2]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 0;
    width: 100%;
  }
`;

const SuccessMessage = styled.div`
  color: #10B981;
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
  margin-top: ${({ theme }) => theme.spacing[2]};
`;

const ErrorMessage = styled.div`
  color: #EF4444;
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
  margin-top: ${({ theme }) => theme.spacing[2]};
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing[6]};
  border-top: 1px solid ${({ theme }) => theme.colors.border.light};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
  }
`;

const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.text.tertiary};
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
  margin: 0;
`;

const LegalLinks = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
  
  a {
    color: ${({ theme }) => theme.colors.text.tertiary};
    font-size: ${({ theme }) => theme.typography.fontSize.sm};
    text-decoration: none;
    transition: color ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
    
    &:hover {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
    align-items: center;
  }
`;

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    
    // Reset states
    setError('');
    setSuccess(false);
    
    // Basic validation
    if (!email || !email.includes('@')) {
      setError('Please enter a valid email address');
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const response = await fetch('https://n8n.bettermarkt.com/webhook/bdbc8e69-2b2e-48b1-bba8-0d80c1f721e2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          subscribedOn: new Date().toISOString(),
          status: 'subscribed'
        }),
      });
      
      if (response.ok) {
        setSuccess(true);
        setEmail('');
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } catch (err) {
      setError('Network error. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FooterWrapper>
      <Container>
        <FooterTop>
          <FooterBrand>
            <Logo to="/">BetterMarkt</Logo>
            <FooterDescription>
              Revolutionizing digital marketing with AI-powered pipelines. We help businesses automate, optimize, and scale their marketing operations.
            </FooterDescription>
            {/* <SocialLinks>
              <SocialLink href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <FiTwitter />
              </SocialLink>
              <SocialLink href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <FiLinkedin />
              </SocialLink>
              <SocialLink href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <FiFacebook />
              </SocialLink>
              <SocialLink href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <FiInstagram />
              </SocialLink>
            </SocialLinks> */}
          </FooterBrand>
          
          <FooterColumn>
            <FooterTitle>Main Pages</FooterTitle>
            <FooterLinks>
              <FooterLink><Link to="/">Home</Link></FooterLink>
              <FooterLink><Link to="/services">Services</Link></FooterLink>
              <FooterLink><Link to="/pricing">Pricing</Link></FooterLink>
              <FooterLink><Link to="/contact">Contact Us</Link></FooterLink>
            </FooterLinks>
          </FooterColumn>
          
          <FooterColumn>
            <FooterTitle>Subscribe</FooterTitle>
            <FooterDescription>
              Get the latest updates and news about AI-powered marketing delivered to your inbox.
            </FooterDescription>
            <NewsletterForm onSubmit={handleNewsletterSubmit}>
              <NewsletterInput 
                type="email" 
                placeholder="Enter your email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                disabled={isSubmitting || success}
              />
              <NewsletterButton 
                type="submit"
                icon={<FiArrowRight />}
                iconPosition="right"
                disabled={isSubmitting || success}
              >
                {isSubmitting ? 'Submitting...' : 'Subscribe'}
              </NewsletterButton>
            </NewsletterForm>
            {success && <SuccessMessage>Thank you for subscribing!</SuccessMessage>}
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </FooterColumn>
        </FooterTop>
        
        <FooterBottom>
          <Copyright>
            &copy; {new Date().getFullYear()} BetterMarkt. All rights reserved.
          </Copyright>
          
          <LegalLinks>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms of Service</Link>
            <Link to="/cookies">Cookie Policy</Link>
          </LegalLinks>
        </FooterBottom>
      </Container>
    </FooterWrapper>
  );
};

export default Footer; 