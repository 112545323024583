import React, { useEffect } from 'react';
import styled from 'styled-components';
import Container from '../components/Container';

// Hero Section
const HeroSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[32]} 0 ${theme.spacing[16]}`};
  background: ${({ theme }) => theme.colors.gradients.primary};
  text-align: center;
  color: white;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => `${theme.spacing[24]} 0 ${theme.spacing[12]}`};
  }
`;

const HeroTitle = styled.h1`
  color: white;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const HeroDescription = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 0 auto;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.typography.fontSize.lg};
  }
`;

// Calendar Section
const CalendarSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const CalendarContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  background: white;
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const InfoBox = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  text-align: center;
`;

const InfoTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  
  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const InfoDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.lg};
  max-width: 700px;
  margin: 0 auto;
`;

const CalendlyWidget = styled.div`
  width: 100%;
  min-height: 700px;
  border-radius: 0 0 ${({ theme }) => theme.borders.radius.lg} ${({ theme }) => theme.borders.radius.lg};
  overflow: hidden;
`;

const SalesContact = () => {
  // Load Calendly script on component mount
  useEffect(() => {
    // Check if the script is already loaded
    if (!document.getElementById('calendly-script')) {
      const script = document.createElement('script');
      script.id = 'calendly-script';
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      document.body.appendChild(script);
      
      // Cleanup on unmount
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <>
      <HeroSection>
        <Container>
          <HeroTitle>Schedule a Demo</HeroTitle>
          <HeroDescription>
            Ready to see BetterMarkt in action? Schedule a personalized demo with our team to explore how our AI-powered marketing platform can transform your business.
          </HeroDescription>
        </Container>
      </HeroSection>
      
      <CalendarSection>
        <Container>
          <CalendarContainer>
            <InfoBox>
              <InfoTitle>Choose a <span>Convenient Time</span></InfoTitle>
              <InfoDescription>
                Select a date and time that works for you, and our team will walk you through our platform, answer your questions, and discuss how BetterMarkt can address your specific marketing challenges.
              </InfoDescription>
            </InfoBox>
            
            <CalendlyWidget>
              {/* Calendly inline widget */}
              <div 
                className="calendly-inline-widget" 
                data-url="https://calendly.com/contact-bettermarkt/30min" 
                style={{ minWidth: '320px', height: '700px' }}
              />
            </CalendlyWidget>
          </CalendarContainer>
        </Container>
      </CalendarSection>
    </>
  );
};

export default SalesContact; 