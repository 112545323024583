import React, { createContext, useState, useContext, useEffect } from 'react';

const PreloaderContext = createContext();

export const PreloaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  
  const completeLoading = () => {
    setLoading(false);
    // Store in session storage so we don't show preloader for page refreshes in the same session
    sessionStorage.setItem('bettermarktPreloaderShown', 'true');
  };
  
  // Check if this is first load
  useEffect(() => {
    const hasPreloaderBeenShown = sessionStorage.getItem('bettermarktPreloaderShown');
    if (hasPreloaderBeenShown) {
      setLoading(false);
    }
  }, []);
  
  return (
    <PreloaderContext.Provider value={{ loading, completeLoading }}>
      {children}
    </PreloaderContext.Provider>
  );
};

export const usePreloader = () => useContext(PreloaderContext);

export default PreloaderContext; 