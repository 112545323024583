import React from 'react';
import styled, { css } from 'styled-components';

// Button variants
const VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  OUTLINE: 'outline',
  TEXT: 'text',
};

// Button sizes
const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const BaseButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.typography.fontFamily.body};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  transition: all ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  cursor: pointer;
  outline: none;
  border: none;
  text-decoration: none;
  white-space: nowrap;
  
  /* Disabled state */
  ${({ disabled }) => 
    disabled && 
    css`
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    `
  }

  /* Icon spacing */
  svg {
    margin-right: ${({ hasLabel, theme }) => (hasLabel ? theme.spacing[2] : '0')};
    
    ${({ iconPosition }) =>
      iconPosition === 'right' &&
      css`
        margin-right: 0;
        margin-left: ${({ theme }) => theme.spacing[2]};
        order: 2;
      `
    }
  }

  /* Size styles */
  ${({ size, theme }) => {
    switch (size) {
      case SIZES.SMALL:
        return css`
          font-size: ${theme.typography.fontSize.sm};
          padding: ${theme.spacing[1]} ${theme.spacing[3]};
          height: 32px;
        `;
      case SIZES.LARGE:
        return css`
          font-size: ${theme.typography.fontSize.lg};
          padding: ${theme.spacing[3]} ${theme.spacing[6]};
          height: 52px;
        `;
      case SIZES.MEDIUM:
      default:
        return css`
          font-size: ${theme.typography.fontSize.md};
          padding: ${theme.spacing[2]} ${theme.spacing[4]};
          height: 42px;
        `;
    }
  }}

  /* Variant styles */
  ${({ variant, theme }) => {
    switch (variant) {
      case VARIANTS.SECONDARY:
        return css`
          background-color: ${theme.colors.secondary.main};
          color: ${theme.colors.secondary.contrast};
          
          &:hover, &:focus {
            background-color: ${theme.colors.secondary.dark};
          }
          
          &:active {
            transform: translateY(1px);
          }
        `;
      case VARIANTS.OUTLINE:
        return css`
          background-color: transparent;
          border: ${theme.borders.width.thin} solid ${theme.colors.primary.main};
          color: ${theme.colors.primary.main};
          
          &:hover, &:focus {
            background-color: ${theme.colors.primary.main}10; /* 10% opacity */
          }
          
          &:active {
            transform: translateY(1px);
          }
        `;
      case VARIANTS.TEXT:
        return css`
          background-color: transparent;
          color: ${theme.colors.primary.main};
          padding-left: ${theme.spacing[2]};
          padding-right: ${theme.spacing[2]};
          height: auto;
          
          &:hover, &:focus {
            background-color: ${theme.colors.primary.main}10; /* 10% opacity */
          }
        `;
      case VARIANTS.PRIMARY:
      default:
        return css`
          background: ${theme.colors.gradients.primary};
          color: ${theme.colors.primary.contrast};
          box-shadow: ${theme.shadows.sm};
          
          &:hover, &:focus {
            background: ${theme.colors.primary.dark};
            box-shadow: ${theme.shadows.md};
          }
          
          &:active {
            transform: translateY(1px);
            box-shadow: ${theme.shadows.sm};
          }
        `;
    }
  }}

  /* Full width */
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `
  }
`;

const Button = ({
  children,
  variant = VARIANTS.PRIMARY,
  size = SIZES.MEDIUM,
  icon = null,
  iconPosition = 'left',
  disabled = false,
  fullWidth = false,
  as = 'button',
  href,
  onClick,
  className,
  ...props
}) => {
  // Determine component type (button, a, etc)
  const Component = as;
  
  // Check if the button has a label
  const hasLabel = Boolean(children);

  // Render button
  return (
    <BaseButton
      as={Component}
      variant={variant}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      className={className}
      hasLabel={hasLabel}
      iconPosition={iconPosition}
      {...props}
    >
      {icon && iconPosition === 'left' && icon}
      {children}
      {icon && iconPosition === 'right' && icon}
    </BaseButton>
  );
};

// Export variant and size constants
Button.VARIANTS = VARIANTS;
Button.SIZES = SIZES;

export default Button; 