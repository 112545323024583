import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Container from '../components/Container';
import { Link } from 'react-router-dom';

// Define fallback colors in case theme is not available
const fallbackColors = {
  primary: {
    main: '#3A0CA3',
    light: '#4CC9F0',
    dark: '#270980',
    contrast: '#FFFFFF'
  },
  text: {
    primary: '#1A1A2E',
    secondary: '#4A4A68',
    tertiary: '#6B7280',
    white: '#FFFFFF'
  },
  background: {
    default: '#FFFFFF',
    light: '#F8FAFC',
    dark: '#1A1A2E',
    card: '#FFFFFF',
    primary: '#FFFFFF',
    secondary: '#F8FAFC',
    tertiary: '#F1F5F9'
  },
  border: {
    light: '#E2E8F0'
  }
};

const HeaderSection = styled.section`
  padding: 120px 0 60px;
  background: linear-gradient(135deg, #3A0CA3 0%, #4361EE 100%);
  text-align: center;
  color: white;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
  }
`;

const HeaderTitle = styled.h1`
  color: white;
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 24px;
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 2.5rem;
  }
`;

const HeaderDescription = styled.p`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 720px;
  margin: 0 auto;
  line-height: 1.7;
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 1.1rem;
  }
`;

const ContentSection = styled.section`
  padding: 60px 0;
  background: ${({ theme }) => theme?.colors?.background?.primary || fallbackColors.background.primary};
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
`;

const SectionSubtitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  line-height: 1.7;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
`;

const List = styled.ul`
  margin-bottom: 20px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  line-height: 1.7;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
`;

const LastUpdated = styled.p`
  font-style: italic;
  color: ${({ theme }) => theme?.colors?.text?.tertiary || fallbackColors.text.tertiary};
  margin-top: 60px;
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
`;

const PolicyLink = styled(Link)`
  color: ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Privacy = () => {
  return (
    <>
      <HeaderSection>
        <Container>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <HeaderTitle>Privacy Policy</HeaderTitle>
            <HeaderDescription>
              At BetterMarkt, we take your privacy seriously. This policy describes how we collect,
              use, and handle your personal information.
            </HeaderDescription>
          </motion.div>
        </Container>
      </HeaderSection>
      
      <ContentSection>
        <Container>
          <ContentContainer>
            <Paragraph>
              <strong>Effective Date: March 20, 2024</strong>
            </Paragraph>
            
            <Paragraph>
              BetterMarkt ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
            </Paragraph>
            
            <SectionTitle>Information We Collect</SectionTitle>
            
            <Paragraph>
              We collect information that you provide directly to us, information we obtain automatically when you use our services, and information from third-party sources, including:
            </Paragraph>
            
            <SectionSubtitle>Personal Information</SectionSubtitle>
            <List>
              <ListItem>Name, email address, and contact details when you create an account or contact us</ListItem>
              <ListItem>Billing information and transaction history when you purchase our services</ListItem>
              <ListItem>Marketing preferences and survey responses</ListItem>
              <ListItem>Communications with our customer support team</ListItem>
            </List>
            
            <SectionSubtitle>Usage Information</SectionSubtitle>
            <List>
              <ListItem>Logging data including IP address, browser type, operating system, pages visited, and time spent</ListItem>
              <ListItem>Device information including device type, model, and operating system</ListItem>
              <ListItem>Service usage patterns and feature interactions</ListItem>
            </List>
            
            <SectionTitle>How We Use Your Information</SectionTitle>
            <Paragraph>
              We use your personal information for various purposes, including:
            </Paragraph>
            
            <List>
              <ListItem>Providing, maintaining, and improving our services</ListItem>
              <ListItem>Processing transactions and sending transaction notifications</ListItem>
              <ListItem>Sending administrative information, such as updates, security alerts, and support messages</ListItem>
              <ListItem>Responding to your comments, questions, and requests</ListItem>
              <ListItem>Sending marketing communications about our products, services, and events</ListItem>
              <ListItem>Monitoring and analyzing trends, usage, and activities in connection with our services</ListItem>
              <ListItem>Detecting, investigating, and preventing fraudulent transactions and other illegal activities</ListItem>
              <ListItem>Personalizing your experience on our platforms</ListItem>
            </List>
            
            <SectionTitle>Data Sharing and Disclosure</SectionTitle>
            <Paragraph>
              We may share your information in the following situations:
            </Paragraph>
            
            <List>
              <ListItem>With service providers who perform services on our behalf</ListItem>
              <ListItem>With business partners with whom we jointly offer products or services</ListItem>
              <ListItem>In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition</ListItem>
              <ListItem>When we believe it is necessary to comply with a legal obligation</ListItem>
              <ListItem>To protect the rights, property, and safety of our company, our users, or others</ListItem>
            </List>
            
            <SectionTitle>Data Security</SectionTitle>
            <Paragraph>
              We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
            </Paragraph>
            
            <SectionTitle>Your Rights and Choices</SectionTitle>
            <Paragraph>
              Depending on your location, you may have certain rights regarding your personal information, including:
            </Paragraph>
            
            <List>
              <ListItem>Accessing, correcting, or deleting your personal information</ListItem>
              <ListItem>Objecting to or restricting certain processing of your data</ListItem>
              <ListItem>Data portability rights</ListItem>
              <ListItem>Withdrawing consent where processing is based on consent</ListItem>
            </List>
            
            <Paragraph>
              To exercise these rights, please contact us at contact@bettermarkt.com.
            </Paragraph>
            
            <SectionTitle>Cookies and Tracking Technologies</SectionTitle>
            <Paragraph>
              We use cookies and similar tracking technologies to track activity on our services and hold certain information. For more information about our use of cookies, please see our <PolicyLink to="/cookies">Cookie Policy</PolicyLink>.
            </Paragraph>
            
            <SectionTitle>International Data Transfers</SectionTitle>
            <Paragraph>
              Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ. If you are located outside the United Arab Emirates and choose to provide information to us, please note that we transfer the data to the United Arab Emirates and process it there.
            </Paragraph>
            
            <SectionTitle>Children's Privacy</SectionTitle>
            <Paragraph>
              Our services are not intended for individuals under the age of 16. We do not knowingly collect personal information from children under 16. If you become aware that a child has provided us with personal information, please contact us.
            </Paragraph>
            
            <SectionTitle>Changes to This Privacy Policy</SectionTitle>
            <Paragraph>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "effective date" at the top of this Privacy Policy.
            </Paragraph>
            
            <SectionTitle>Contact Us</SectionTitle>
            <Paragraph>
              If you have any questions about this Privacy Policy, please contact us at:
            </Paragraph>
            <Paragraph>
              Email: contact@bettermarkt.com<br />
              Address: Dubai, UAE
            </Paragraph>
            
            <LastUpdated>Last updated: March 20, 2024</LastUpdated>
          </ContentContainer>
        </Container>
      </ContentSection>
    </>
  );
};

export default Privacy; 