import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const PreloaderContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Logo = styled(motion.div)`
  margin-bottom: 40px;
  position: relative;
  width: 120px;
  height: 120px;
`;

const LogoText = styled(motion.h1)`
  color: ${({ theme }) => theme.colors.primary.main};
  font-size: 28px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  margin-top: 20px;
  opacity: 0;
`;

const ProgressContainer = styled.div`
  width: 200px;
  height: 4px;
  background: ${({ theme }) => `${theme.colors.primary.main}20`};
  border-radius: 4px;
  margin-bottom: 20px;
  overflow: hidden;
`;

const ProgressBar = styled(motion.div)`
  height: 100%;
  background: ${({ theme }) => theme.colors.primary.main};
  width: 0%;
`;

const LoadingText = styled(motion.p)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 14px;
  margin-top: 15px;
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const loadingTexts = [
  "Optimizing content strategies...",
  "Analyzing marketing workflows...",
  "Building AI pipelines...",
  "Connecting marketing channels...",
  "Preparing personalization engine..."
];

const Preloader = ({ onComplete }) => {
  const [progress, setProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState(loadingTexts[0]);
  const canvasRef = useRef(null);
  const particlesRef = useRef([]);
  const animationRef = useRef(null);
  const [isComplete, setIsComplete] = useState(false);

  // Canvas setup for particle animation
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    const createParticles = () => {
      const particles = [];
      const particleCount = 80;
      
      for (let i = 0; i < particleCount; i++) {
        particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          radius: Math.random() * 3 + 1,
          color: `hsl(${Math.random() * 60 + 180}, 80%, 60%)`,
          velocity: {
            x: (Math.random() - 0.5) * 1.5,
            y: (Math.random() - 0.5) * 1.5
          },
          opacity: Math.random() * 0.5 + 0.1
        });
      }
      
      return particles;
    };
    
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      particlesRef.current = createParticles();
    };
    
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    
    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Draw particles
      particlesRef.current.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fillStyle = particle.color.replace(')', `, ${particle.opacity})`);
        ctx.fill();
        
        // Update position
        particle.x += particle.velocity.x;
        particle.y += particle.velocity.y;
        
        // Boundary check
        if (particle.x < 0 || particle.x > canvas.width) {
          particle.velocity.x = -particle.velocity.x;
        }
        
        if (particle.y < 0 || particle.y > canvas.height) {
          particle.velocity.y = -particle.velocity.y;
        }
      });
      
      // Connect particles that are close
      particlesRef.current.forEach((particle, i) => {
        particlesRef.current.slice(i + 1).forEach(otherParticle => {
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < 120) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(14, 165, 233, ${0.2 * (1 - distance / 120)})`;
            ctx.lineWidth = 0.5;
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.stroke();
          }
        });
      });
      
      animationRef.current = requestAnimationFrame(drawParticles);
    };
    
    drawParticles();
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationRef.current);
    };
  }, []);
  
  // Simulate loading progress
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          setTimeout(() => {
            setIsComplete(true);
            setTimeout(() => {
              if (onComplete) onComplete();
            }, 500);
          }, 300);
          return 100;
        }
        return prev + 1;
      });
      
      // Update loading message periodically
      if (progress % 20 === 0) {
        const messageIndex = Math.floor(progress / 20);
        if (messageIndex < loadingTexts.length) {
          setLoadingMessage(loadingTexts[messageIndex]);
        }
      }
    }, 30);
    
    return () => clearInterval(interval);
  }, [progress, onComplete]);
  
  // Logo animation variants
  const logoVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { 
      scale: 1, 
      opacity: 1,
      transition: { 
        duration: 0.8, 
        ease: "easeOut" 
      }
    },
    exit: { 
      scale: 1.1, 
      opacity: 0,
      transition: { 
        duration: 0.4, 
        ease: "easeIn" 
      }
    }
  };
  
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        delay: 0.3, 
        duration: 0.6, 
        ease: "easeOut" 
      }
    },
    exit: { 
      opacity: 0,
      y: -20,
      transition: { 
        duration: 0.3, 
        ease: "easeIn" 
      }
    }
  };
  
  const containerVariants = {
    exit: {
      opacity: 0,
      transition: { 
        duration: 0.5, 
        ease: "easeInOut" 
      }
    }
  };
  
  return (
    <AnimatePresence>
      {!isComplete && (
        <PreloaderContainer
          initial={{ opacity: 1 }}
          exit={containerVariants.exit}
          key="preloader"
        >
          <Canvas ref={canvasRef} />
          
          <Logo
            variants={logoVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="60" cy="60" r="50" stroke="#0EA5E9" strokeWidth="2" />
              <path 
                d="M40 45 L40 75 L80 75 L80 45 Z" 
                stroke="#0EA5E9" 
                strokeWidth="2" 
                fill="none"
              />
              <path 
                d="M50 55 L70 55" 
                stroke="#0EA5E9" 
                strokeWidth="2"
              />
              <path 
                d="M50 65 L70 65" 
                stroke="#0EA5E9" 
                strokeWidth="2"
              />
            </svg>
          </Logo>
          
          <LogoText
            variants={textVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            BetterMarkt
          </LogoText>
          
          <ProgressContainer>
            <ProgressBar
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.1 }}
            />
          </ProgressContainer>
          
          <LoadingText
            key={loadingMessage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {loadingMessage}
          </LoadingText>
        </PreloaderContainer>
      )}
    </AnimatePresence>
  );
};

export default Preloader; 