// Theme configuration for BetterMarkt website
// Modern teal/green theme with warm accents - professional yet fresh

const theme = {
  // Color palette
  colors: {
    // Primary colors
    primary: {
      main: '#0EA5E9', // Bright teal - primary brand color
      light: '#38BDF8', // Light teal - softer variant
      dark: '#0369A1', // Dark teal - stronger variant
      contrast: '#FFFFFF', // White - text on primary
    },
    // Secondary colors
    secondary: {
      main: '#10B981', // Emerald green - secondary brand color
      light: '#34D399', // Light green - softer variant
      dark: '#059669', // Dark green - stronger variant
      contrast: '#FFFFFF', // White - text on secondary
    },
    // Accent colors for highlights
    accent: {
      coral: '#F97316', // Vibrant coral - for attention-grabbing elements
      purple: '#A855F7', // Soft purple - for premium elements
      yellow: '#FBBF24', // Gold/yellow - for special highlights
      pink: '#EC4899', // Pink - for error states
    },
    // Background colors
    background: {
      primary: '#FFFFFF', // White - main background
      secondary: '#F0FDFA', // Very light teal tint - secondary background
      tertiary: '#ECFDF5', // Light green tint - tertiary background
      card: '#FFFFFF', // White - card background
    },
    // Text colors
    text: {
      primary: '#0F172A', // Very dark blue-gray - primary text
      secondary: '#334155', // Dark slate - secondary text
      tertiary: '#64748B', // Medium slate - tertiary text
      disabled: '#94A3B8', // Light slate - disabled text
    },
    // Border colors
    border: {
      light: '#E2E8F0', // Very light gray-blue - light border
      main: '#CBD5E1', // Light gray-blue - main border
      dark: '#94A3B8', // Medium gray-blue - dark border
    },
    // System colors
    system: {
      error: '#EF4444', // Red - error states
      warning: '#F59E0B', // Amber - warning states
      success: '#10B981', // Green - success states
      info: '#0EA5E9', // Teal - information states
    },
    // Gradient definitions
    gradients: {
      primary: 'linear-gradient(90deg, #0EA5E9 0%, #38BDF8 100%)',
      secondary: 'linear-gradient(90deg, #10B981 0%, #34D399 100%)',
      accent: 'linear-gradient(90deg, #0EA5E9 0%, #10B981 100%)',
      call: 'linear-gradient(90deg, #F97316 0%, #FBBF24 100%)',
    },
  },

  // Typography
  typography: {
    fontFamily: {
      heading: "'Poppins', sans-serif",
      body: "'Inter', sans-serif",
      mono: "'JetBrains Mono', monospace",
    },
    fontSize: {
      xs: '0.75rem', // 12px
      sm: '0.875rem', // 14px
      md: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.25rem', // 20px
      '2xl': '1.5rem', // 24px
      '3xl': '1.875rem', // 30px
      '4xl': '2.25rem', // 36px
      '5xl': '3rem', // 48px
      '6xl': '3.75rem', // 60px
    },
    fontWeight: {
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
    lineHeight: {
      none: 1,
      tight: 1.25,
      snug: 1.375,
      normal: 1.5,
      relaxed: 1.625,
      loose: 2,
    },
    letterSpacing: {
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em',
    },
  },

  // Spacing
  spacing: {
    0: '0',
    1: '0.25rem', // 4px
    2: '0.5rem', // 8px
    3: '0.75rem', // 12px
    4: '1rem', // 16px
    5: '1.25rem', // 20px
    6: '1.5rem', // 24px
    8: '2rem', // 32px
    10: '2.5rem', // 40px
    12: '3rem', // 48px
    16: '4rem', // 64px
    20: '5rem', // 80px
    24: '6rem', // 96px
    32: '8rem', // 128px
    40: '10rem', // 160px
    48: '12rem', // 192px
    56: '14rem', // 224px
    64: '16rem', // 256px
  },

  // Borders
  borders: {
    radius: {
      none: '0',
      sm: '0.125rem', // 2px
      md: '0.25rem', // 4px
      lg: '0.5rem', // 8px
      xl: '0.75rem', // 12px
      '2xl': '1rem', // 16px
      '3xl': '1.5rem', // 24px
      full: '9999px',
    },
    width: {
      thin: '1px',
      medium: '2px',
      thick: '4px',
    },
  },

  // Shadows
  shadows: {
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    none: 'none',
  },

  // Z-index
  zIndex: {
    0: 0,
    10: 10,
    20: 20,
    30: 30,
    40: 40,
    50: 50,
    auto: 'auto',
  },

  // Breakpoints for responsive design
  breakpoints: {
    xs: '0px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },

  // Transitions
  transitions: {
    duration: {
      fast: '150ms',
      normal: '300ms',
      slow: '500ms',
      slower: '700ms',
    },
    timing: {
      ease: 'ease',
      in: 'ease-in',
      out: 'ease-out',
      inOut: 'ease-in-out',
    },
  },
};

export default theme; 