import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

// Container component for consistent horizontal padding
export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
`;

// Section component with consistent vertical padding
export const Section = styled.section`
  padding: 80px 0;
  position: relative;
  
  @media (max-width: 768px) {
    padding: 60px 0;
  }
`;

// Card component for consistent styling
export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
`;

// Button component with variants
export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.$size === 'large' ? '0.875rem 1.75rem' : '0.625rem 1.25rem'};
  font-size: ${props => props.$size === 'large' ? '1.125rem' : '1rem'};
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  border: 2px solid transparent;
  
  ${props => props.$variant === 'outlined' && css`
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.primary.main};
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.light};
    }
  `}
  
  ${props => props.$variant === 'light' && css`
    background-color: #FFFFFF;
    color: ${({ theme }) => theme.colors.primary.main};
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
      transform: translateY(-2px);
    }
  `}
  
  ${props => !props.$variant && css`
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: #FFFFFF;
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.dark};
      transform: translateY(-2px);
      color: #FFFFFF;
    }
  `}
  
  ${props => props.$fullWidth && css`
    width: 100%;
  `}
`;

// Flex container for layout
export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.$direction || 'row'};
  justify-content: ${props => props.$justify || 'flex-start'};
  align-items: ${props => props.$align || 'stretch'};
  flex-wrap: ${props => props.$wrap || 'nowrap'};
  gap: ${props => props.$gap || '0'};
`;

// Grid container for layout
export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.$columns || 'repeat(auto-fit, minmax(250px, 1fr))'};
  gap: ${props => props.$gap || '1rem'};
`;

// Text components
export const Heading = styled.h2`
  font-size: ${props => {
    switch (props.$level) {
      case 1: return '2.5rem';
      case 2: return '2rem';
      case 3: return '1.5rem';
      case 4: return '1.25rem';
      default: return '2rem';
    }
  }};
  font-weight: 700;
  margin-bottom: ${props => props.$mb || '1.5rem'};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 1.2;
`;

export const Text = styled.p`
  font-size: ${props => props.$size || '1rem'};
  line-height: 1.6;
  margin-bottom: ${props => props.$mb || '1rem'};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

// Badge component
export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 20px;
  background-color: ${props => props.$bg || props.theme.colors.primary.light};
  color: ${props => props.$color || props.theme.colors.primary.main};
`;

// Divider component
export const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border};
  margin: ${props => props.$my || '2rem'} 0;
`;

// Animation components
export const FadeIn = styled(motion.div).attrs({
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
})``;

export const ScaleIn = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 0.9 },
  animate: { opacity: 1, scale: 1 },
  transition: { duration: 0.6 }
})``;

// Icon Button
export const IconButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.$size || '40px'};
  height: ${props => props.$size || '40px'};
  border-radius: 50%;
  background-color: ${props => props.$bg || 'transparent'};
  color: ${props => props.$color || props.theme.colors.text.primary};
  border: ${props => props.$border ? `1px solid ${props.theme.colors.border}` : 'none'};
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }
`; 