import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Container from '../components/Container';
import { Link } from 'react-router-dom';

// Define fallback colors in case theme is not available
const fallbackColors = {
  primary: {
    main: '#3A0CA3',
    light: '#4CC9F0',
    dark: '#270980',
    contrast: '#FFFFFF'
  },
  text: {
    primary: '#1A1A2E',
    secondary: '#4A4A68',
    tertiary: '#6B7280',
    white: '#FFFFFF'
  },
  background: {
    default: '#FFFFFF',
    light: '#F8FAFC',
    dark: '#1A1A2E',
    card: '#FFFFFF',
    primary: '#FFFFFF',
    secondary: '#F8FAFC',
    tertiary: '#F1F5F9'
  },
  border: {
    light: '#E2E8F0'
  }
};

const HeaderSection = styled.section`
  padding: 120px 0 60px;
  background: linear-gradient(135deg, #3A0CA3 0%, #4361EE 100%);
  text-align: center;
  color: white;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
  }
`;

const HeaderTitle = styled.h1`
  color: white;
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 24px;
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 2.5rem;
  }
`;

const HeaderDescription = styled.p`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 720px;
  margin: 0 auto;
  line-height: 1.7;
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 1.1rem;
  }
`;

const ContentSection = styled.section`
  padding: 60px 0;
  background: ${({ theme }) => theme?.colors?.background?.primary || fallbackColors.background.primary};
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
`;

const SectionSubtitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  line-height: 1.7;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
`;

const List = styled.ul`
  margin-bottom: 20px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  line-height: 1.7;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
`;

const TableContainer = styled.div`
  margin: 30px 0;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
`;

const TableHeader = styled.th`
  padding: 12px 20px;
  background-color: ${({ theme }) => theme?.colors?.background?.secondary || fallbackColors.background.secondary};
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  text-align: left;
  border: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
  font-weight: 600;
`;

const TableCell = styled.td`
  padding: 12px 20px;
  border: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
`;

const LastUpdated = styled.p`
  font-style: italic;
  color: ${({ theme }) => theme?.colors?.text?.tertiary || fallbackColors.text.tertiary};
  margin-top: 60px;
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
`;

const PolicyLink = styled(Link)`
  color: ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Cookies = () => {
  return (
    <>
      <HeaderSection>
        <Container>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <HeaderTitle>Cookie Policy</HeaderTitle>
            <HeaderDescription>
              This Cookie Policy explains how BetterMarkt uses cookies and similar technologies
              to recognize you when you visit our website.
            </HeaderDescription>
          </motion.div>
        </Container>
      </HeaderSection>
      
      <ContentSection>
        <Container>
          <ContentContainer>
            <Paragraph>
              <strong>Effective Date: March 20, 2024</strong>
            </Paragraph>
            
            <Paragraph>
              This Cookie Policy explains how BetterMarkt ("we", "our", or "us") uses cookies and similar technologies to recognize you when you visit our website at bettermarkt.com ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
            </Paragraph>
            
            <SectionTitle>What Are Cookies?</SectionTitle>
            
            <Paragraph>
              Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.
            </Paragraph>
            
            <Paragraph>
              Cookies set by the website owner (in this case, BetterMarkt) are called "first-party cookies". Cookies set by parties other than the website owner are called "third-party cookies". Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
            </Paragraph>
            
            <SectionTitle>Why Do We Use Cookies?</SectionTitle>
            
            <Paragraph>
              We use first-party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Website. Third parties serve cookies through our Website for advertising, analytics, and other purposes.
            </Paragraph>
            
            <SectionTitle>Types of Cookies We Use</SectionTitle>
            
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <TableHeader>Type of Cookie</TableHeader>
                    <TableHeader>Description</TableHeader>
                    <TableHeader>Who Serves These Cookies</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableCell>Essential Website Cookies</TableCell>
                    <TableCell>These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.</TableCell>
                    <TableCell>BetterMarkt</TableCell>
                  </tr>
                  <tr>
                    <TableCell>Performance and Functionality Cookies</TableCell>
                    <TableCell>These cookies are used to enhance the performance and functionality of our Website but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.</TableCell>
                    <TableCell>BetterMarkt<br />Google Analytics</TableCell>
                  </tr>
                  <tr>
                    <TableCell>Analytics and Customization Cookies</TableCell>
                    <TableCell>These cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.</TableCell>
                    <TableCell>Google Analytics<br />Hotjar<br />Mixpanel</TableCell>
                  </tr>
                  <tr>
                    <TableCell>Advertising Cookies</TableCell>
                    <TableCell>These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed, and in some cases selecting advertisements that are based on your interests.</TableCell>
                    <TableCell>Google Ads<br />Facebook<br />LinkedIn</TableCell>
                  </tr>
                  <tr>
                    <TableCell>Social Media Cookies</TableCell>
                    <TableCell>These cookies are used to enable you to share pages and content that you find interesting on our Website through third-party social networking and other websites. These cookies may also be used for advertising purposes.</TableCell>
                    <TableCell>Facebook<br />Twitter<br />LinkedIn</TableCell>
                  </tr>
                </tbody>
              </Table>
            </TableContainer>
            
            <SectionTitle>How Can You Control Cookies?</SectionTitle>
            
            <Paragraph>
              You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by clicking on the appropriate opt-out links provided in the cookie table above.
            </Paragraph>
            
            <Paragraph>
              You can also set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.
            </Paragraph>
            
            <SectionSubtitle>Browser Controls</SectionSubtitle>
            <Paragraph>
              Most web browsers allow you to manage your cookie preferences. You can:
            </Paragraph>
            
            <List>
              <ListItem>Delete cookies from your browser</ListItem>
              <ListItem>Block cookies by activating settings on your browser that allow you to refuse all or some cookies</ListItem>
              <ListItem>Allow cookies from specific websites only</ListItem>
              <ListItem>Block third-party cookies in your browser</ListItem>
            </List>
            
            <Paragraph>
              Where the organization setting the cookie provides an automated disabling tool in respect of its cookie(s), we list the name of that organization, the category of cookies it sets together with a link to its automated disabling tool. In all other cases, we list the names of the cookies themselves and their source at the date of this Cookie Policy so that you can easily identify and disable them if you want through your browser controls.
            </Paragraph>
            
            <SectionTitle>How Often Will We Update This Cookie Policy?</SectionTitle>
            
            <Paragraph>
              We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
            </Paragraph>
            
            <Paragraph>
              The date at the top of this Cookie Policy indicates when it was last updated.
            </Paragraph>
            
            <SectionTitle>Where Can You Get Further Information?</SectionTitle>
            
            <Paragraph>
              If you have any questions about our use of cookies or other technologies, please email us at contact@bettermarkt.com or by post to:
            </Paragraph>
            
            <Paragraph>
              BetterMarkt<br />
              Dubai, UAE
            </Paragraph>
            
            <Paragraph>
              For more information about our privacy practices, please review our <PolicyLink to="/privacy">Privacy Policy</PolicyLink>.
            </Paragraph>
            
            <LastUpdated>Last updated: March 20, 2024</LastUpdated>
          </ContentContainer>
        </Container>
      </ContentSection>
    </>
  );
};

export default Cookies; 