import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiCheck, FiX, FiPercent, FiRefreshCw, FiDollarSign } from 'react-icons/fi';
import Container from '../components/Container';
import Button from '../components/Button';

// Define fallback colors similar to Services page
const fallbackColors = {
  primary: {
    main: '#3A0CA3',
    light: '#4CC9F0',
    dark: '#270980',
    contrast: '#FFFFFF'
  },
  text: {
    primary: '#1A1A2E',
    secondary: '#4A4A68',
    white: '#FFFFFF',
    tertiary: '#6B7280'
  },
  background: {
    default: '#FFFFFF',
    light: '#F8FAFC',
    dark: '#1A1A2E',
    card: '#FFFFFF',
    primary: '#FFFFFF',
    secondary: '#F8FAFC',
    tertiary: '#F1F5F9'
  },
  accent: {
    coral: '#F97316',
    purple: '#A855F7',
    teal: '#14B8A6'
  },
  border: {
    light: '#E2E8F0'
  }
};

// Animations
const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(58, 12, 163, 0.4); }
  70% { box-shadow: 0 0 0 15px rgba(58, 12, 163, 0); }
  100% { box-shadow: 0 0 0 0 rgba(58, 12, 163, 0); }
`;

// Improved Pricing Header Section
const HeaderSection = styled.section`
  position: relative;
  padding: 120px 0 100px;
  background: linear-gradient(135deg, #3A0CA3 0%, #4361EE 100%);
  text-align: center;
  color: white;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(255,255,255,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E");
    opacity: 0.5;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
  }
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    padding: 100px 0 80px;
  }
`;

const HeaderContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1000px;
  margin: 0 auto;
`;

const FloatingIcon = styled(motion.div)`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  z-index: 1;
  animation: ${floatAnimation} 5s ease-in-out infinite;
  animation-delay: ${props => props.delay || '0s'};
  
  &.dollar {
    top: 15%;
    left: 15%;
  }
  
  &.percent {
    top: 25%;
    right: 15%;
    animation-delay: 1s;
  }
  
  &.refresh {
    bottom: 20%;
    left: 20%;
    animation-delay: 2s;
  }
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.lg || '992px'}) {
    display: none;
  }
`;

const HeaderSubtitle = styled.div`
  display: inline-block;
  margin-bottom: 16px;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.9);

  ${props => props.darkText && `
    background: rgba(67, 97, 238, 0.1);
    color: ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main};
  `}
`;

const SectionTitle = styled.h1`
  color: white;
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 24px;
  line-height: 1.2;
  
  span {
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 5px;
      width: 100%;
      height: 12px;
      background-color: rgba(76, 201, 240, 0.3);
      z-index: -1;
    }
  }
  
  ${props => props.darkText && `
    color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
    
    span::after {
      background-color: rgba(76, 201, 240, 0.3);
    }
  `}
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 2.75rem;
  }
`;

const SectionDescription = styled.p`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 720px;
  margin: 0 auto 40px;
  line-height: 1.7;
  
  ${props => props.darkText && `
    color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  `}
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 1.1rem;
  }
`;

// Improved Toggle Component
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 50px;
  padding: 8px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

const ToggleOption = styled.span`
  font-size: 16px;
  font-weight: 600;
  padding: 10px 16px;
  color: ${({ active }) => active ? 'white' : 'rgba(255, 255, 255, 0.7)'};
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  
  &:hover {
    color: white;
  }
`;

const ToggleSwitch = styled.div`
  position: relative;
  width: 60px;
  height: 34px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &::after {
    content: '';
    position: absolute;
    top: 4px;
    left: ${({ toggled }) => (toggled ? '30px' : '4px')};
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: white;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    animation: ${({ toggled }) => toggled ? pulseAnimation : 'none'} 1.5s infinite;
  }
  
  &:hover::after {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
`;

const SaveTag = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme?.colors?.accent?.coral || fallbackColors.accent.coral};
  color: white;
  font-size: 12px;
  font-weight: 700;
  margin-left: 8px;
  box-shadow: 0 3px 6px rgba(249, 115, 22, 0.3);
  transform: translateY(-1px);
`;

const ContactInfo = styled.div`
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
  max-width: 720px;
  margin: 0 auto 40px;
  line-height: 1.7;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 16px;
  }
`;

const ContactHighlight = styled.span`
  font-weight: 700;
  color: white;
  display: inline-block;
  margin-top: 8px;
  font-size: 20px;
`;

// Pricing Tiers Section
const PricingSection = styled.section`
  padding: 80px 0 100px;
  background: white;
`;

const TiersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 30px;
  margin-top: 60px;
  position: relative;
  z-index: 10;
`;

const PricingTier = styled(motion.div)`
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  border: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
  display: flex;
  flex-direction: column;
  height: 100%;
  
  ${({ featured }) => featured && `
    box-shadow: 0 20px 40px rgba(58, 12, 163, 0.15);
    transform: translateY(-10px);
    border: none;
    
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 16px;
      padding: 2px;
      background: linear-gradient(135deg, #4CC9F0 0%, #3A0CA3 100%);
      -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }
  `}
  
  &:hover {
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }
`;

const TierHeader = styled.div`
  padding: 40px 30px 30px;
  text-align: center;
  position: relative;
  
  ${({ featured, theme }) => featured && `
    background: linear-gradient(135deg, ${theme?.colors?.primary?.light || fallbackColors.primary.light}10 0%, ${theme?.colors?.primary?.main || fallbackColors.primary.main}10 100%);
  `}
`;

const TierName = styled.h3`
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 700;
  color: ${({ theme, featured }) => featured ? 
    theme?.colors?.primary?.main || fallbackColors.primary.main : 
    theme?.colors?.text?.primary || fallbackColors.text.primary};
`;

const TierDescription = styled.p`
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  font-size: 15px;
  margin-bottom: 24px;
  min-height: 46px;
`;

const PriceContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Price = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 5px;
`;

const Currency = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  margin-right: 4px;
`;

const PriceAmount = styled.span`
  font-size: 48px;
  font-weight: 800;
  line-height: 1;
  background: ${({ featured }) => featured ? 
    'linear-gradient(135deg, #4CC9F0 0%, #3A0CA3 100%)' : 
    'none'};
  ${({ featured }) => featured ? 
    '-webkit-background-clip: text; -webkit-text-fill-color: transparent;' : 
    ''};
  color: ${({ theme, featured }) => !featured ? 
    theme?.colors?.text?.primary || fallbackColors.text.primary : 
    'inherit'};
`;

const BillingPeriod = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme?.colors?.text?.tertiary || fallbackColors.text.tertiary};
  font-weight: 500;
  margin-left: 4px;
`;

const PriceCaption = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme?.colors?.text?.tertiary || fallbackColors.text.tertiary};
`;

const TierContent = styled.div`
  padding: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 30px;
  flex-grow: 1;
`;

const Feature = styled.li`
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 0.5s forwards;
  animation-delay: ${({ index }) => 0.1 + index * 0.05}s;
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const FeatureIcon = styled.span`
  margin-right: 12px;
  color: ${({ included, theme }) => included ? 
    theme?.colors?.primary?.main || fallbackColors.primary.main : 
    theme?.colors?.text?.tertiary || fallbackColors.text.tertiary};
  display: flex;
  align-items: center;
  font-size: 18px;
`;

const FeatureText = styled.span`
  flex: 1;
  padding-top: 1px;
  
  ${({ strong }) => strong && `
    font-weight: 600;
  `}
`;

const FeatureGroup = styled.div`
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const FeatureGroupName = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  margin-bottom: 12px;
  font-size: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
`;

const TierFooter = styled.div`
  padding: 0 30px 40px;
  text-align: center;
`;

const TierButton = styled(Button)`
  width: 100%;
  padding: 14px 20px;
  transition: all 0.3s ease;
  
  ${({ featured }) => featured ? `
    background: linear-gradient(135deg, #4CC9F0 0%, #3A0CA3 100%);
    color: white;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.5px;
    box-shadow: 0 10px 20px rgba(58, 12, 163, 0.2);
    border: none;
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%);
      transition: all 0.6s ease;
    }
    
    &:hover {
      box-shadow: 0 15px 25px rgba(58, 12, 163, 0.3);
      transform: translateY(-3px);
      
      &::before {
        left: 100%;
      }
    }
    
    &:active {
      transform: translateY(-1px);
      box-shadow: 0 8px 15px rgba(58, 12, 163, 0.25);
    }
  ` : ''}
`;

const PriceUnit = ({ tier, isAnnual }) => {
  return null;
};

// Implementation Packages Section
const ImplementationHeaderSection = styled.section`
  padding: 100px 0 40px;
  background: linear-gradient(to bottom, ${({ theme }) => theme?.colors?.background?.primary || fallbackColors.background.primary}, ${({ theme }) => theme?.colors?.background?.tertiary || fallbackColors.background.tertiary});
  text-align: center;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: linear-gradient(135deg, 
      ${({ theme }) => theme?.colors?.primary?.light || fallbackColors.primary.light}10, 
      ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main}10);
    top: -50px;
    right: -100px;
    filter: blur(40px);
    z-index: 0;
  }
`;

const ImplementationHeaderDescription = styled.p`
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  font-size: ${({ theme }) => theme?.typography?.fontSize?.lg || '18px'};
  max-width: 800px;
  margin: 20px auto 0;
  line-height: 1.6;
`;

const ImplementationSection = styled.section`
  padding: 60px 0 120px;
  background: linear-gradient(to bottom, white, ${({ theme }) => theme?.colors?.background?.tertiary || fallbackColors.background.tertiary});
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background: linear-gradient(135deg, 
      ${({ theme }) => theme?.colors?.primary?.light || fallbackColors.primary.light}15, 
      ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main}15);
    top: -200px;
    left: -100px;
    filter: blur(30px);
    z-index: 0;
  }
  
  &::after {
    content: '';
    position: absolute;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background: linear-gradient(135deg, 
      ${({ theme }) => theme?.colors?.accent?.coral || fallbackColors.accent.coral}10, 
      ${({ theme }) => theme?.colors?.accent?.teal || fallbackColors.accent.teal}10);
    bottom: -150px;
    right: -50px;
    filter: blur(40px);
    z-index: 0;
  }
`;

const ImplementationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 40px;
  margin-top: 70px;
  position: relative;
  z-index: 1;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

const ImplementationCard = styled(motion.div)`
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.06);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.03);
  
  &:hover {
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
    
    &::before {
      opacity: 1;
    }
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: ${props => {
      if (props.index === 0) return 'linear-gradient(90deg, #3A0CA3, #4361EE)';
      if (props.index === 1) return 'linear-gradient(90deg, #F97316, #FDBA74)';
      return 'linear-gradient(90deg, #10B981, #34D399)';
    }};
    opacity: 0.7;
    transition: opacity 0.4s ease;
  }
`;

const CardHeader = styled.div`
  padding: 35px 35px 25px;
  position: relative;
  z-index: 1;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 35px;
    right: 35px;
    height: 1px;
    background: linear-gradient(to right, 
      ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light}, 
      transparent);
  }
`;

const PackageName = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  display: flex;
  align-items: center;
  position: relative;
  
  &::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 14px;
    background: ${({ packageType }) => {
      if (packageType === 'Standard') return '#3A0CA3';
      if (packageType === 'Advanced') return '#F97316';
      return '#10B981';
    }};
    border-radius: 50%;
    box-shadow: 0 0 12px ${({ packageType }) => {
      if (packageType === 'Standard') return 'rgba(58, 12, 163, 0.5)';
      if (packageType === 'Advanced') return 'rgba(249, 115, 22, 0.5)';
      return 'rgba(16, 185, 129, 0.5)';
    }};
  }
`;

const PackageDescription = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  margin: 0;
`;

const PackagePrice = styled.div`
  margin: 25px 0 18px;
  font-size: 32px;
  font-weight: 800;
  color: ${({ theme, packageType }) => {
    if (packageType === 'Standard') return theme?.colors?.primary?.main || fallbackColors.primary.main;
    if (packageType === 'Advanced') return theme?.colors?.accent?.coral || fallbackColors.accent.coral;
    return theme?.colors?.accent?.teal || '#10B981';
  }};
  display: flex;
  align-items: baseline;
  
  span {
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
    margin-left: 8px;
    opacity: 0.8;
  }
`;

const TimelineIndicator = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 7px 14px;
  background: ${({ packageType }) => {
    if (packageType === 'Standard') return 'rgba(58, 12, 163, 0.08)';
    if (packageType === 'Advanced') return 'rgba(249, 115, 22, 0.08)';
    return 'rgba(16, 185, 129, 0.08)';
  }};
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ packageType }) => {
    if (packageType === 'Standard') return '#3A0CA3';
    if (packageType === 'Advanced') return '#F97316';
    return '#10B981';
  }};
  margin-bottom: 25px;
  
  svg {
    margin-right: 8px;
    stroke: ${({ packageType }) => {
      if (packageType === 'Standard') return '#3A0CA3';
      if (packageType === 'Advanced') return '#F97316';
      return '#10B981';
    }};
    stroke-width: 2;
  }
`;

const CardContent = styled.div`
  padding: 30px 35px;
  flex-grow: 1;
`;

const PackageFeatures = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const PackageFeature = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 18px;
  font-size: 15px;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  position: relative;
  padding-left: 32px;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 0.5s forwards;
  animation-delay: ${({ index }) => 0.2 + index * 0.1}s;
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 20px;
    height: 20px;
    background: ${({ packageType }) => {
      if (packageType === 'Standard') return 'rgba(58, 12, 163, 0.1)';
      if (packageType === 'Advanced') return 'rgba(249, 115, 22, 0.1)';
      return 'rgba(16, 185, 129, 0.1)';
    }};
    border-radius: 50%;
  }
  
  &::after {
    content: '✓';
    position: absolute;
    left: 0;
    top: 2px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ packageType }) => {
      if (packageType === 'Standard') return '#3A0CA3';
      if (packageType === 'Advanced') return '#F97316';
      return '#10B981';
    }};
    font-size: 12px;
    font-weight: 700;
  }
`;

const CardFooter = styled.div`
  padding: 0 35px 35px;
  text-align: center;
`;

const PackageButton = styled(Button)`
  width: 100%;
  padding: 15px 20px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 10px;
  font-weight: 600;
  letter-spacing: 0.3px;
  
  background: ${({ packageType }) => {
    if (packageType === 'Standard') return 'linear-gradient(135deg, #3A0CA3, #4361EE)';
    if (packageType === 'Advanced') return 'linear-gradient(135deg, #F97316, #FDBA74)';
    return 'linear-gradient(135deg, #10B981, #34D399)';
  }};
  
  color: white;
  border: none;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
    transition: all 0.6s ease;
  }
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: ${({ packageType }) => {
      if (packageType === 'Standard') return '0 12px 30px rgba(58, 12, 163, 0.2)';
      if (packageType === 'Advanced') return '0 12px 30px rgba(249, 115, 22, 0.2)';
      return '0 12px 30px rgba(16, 185, 129, 0.2)';
    }};
    
    &::before {
      left: 100%;
    }
  }
  
  &:active {
    transform: translateY(-2px);
  }
`;

// À La Carte Section
const ALaCarteSection = styled.section`
  padding: 100px 0;
  background: white;
  position: relative;
  overflow: hidden;
`;

const ALaCarteHeader = styled.div`
  text-align: center;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
`;

const ALaCarteGrid = styled.div`
  position: relative;
  z-index: 1;
`;

const ALaCarteTable = styled(motion.div)`
  width: 100%;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(76, 201, 240, 0.03) 0%, rgba(58, 12, 163, 0.03) 100%);
    pointer-events: none;
  }
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px 30px;
  background: linear-gradient(45deg, rgba(76, 201, 240, 0.08) 0%, rgba(58, 12, 163, 0.08) 100%);
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    display: none;
  }
`;

const TableHeading = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  text-align: left;
`;

const TableRow = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr;
  padding: 24px 30px;
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.border?.light || fallbackColors.border.light};
  transition: all 0.3s ease;
  
  &:last-child {
    border-bottom: none;
  }
  
  &:hover {
    background: ${({ theme }) => theme?.colors?.background?.tertiary || fallbackColors.background.tertiary};
  }
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 12px;
  }
`;

const TableCell = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  align-self: center;
  
  &:not(:first-child) {
    text-align: center;
  }
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    text-align: left;
    
    &:not(:first-child)::before {
      content: ${props => props.label ? `"${props.label}: "` : 'none'};
      font-weight: 600;
      color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
    }
  }
`;

const ServiceName = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 10px;
    font-size: 20px;
    color: ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main};
    opacity: 0.8;
  }
`;

const ServiceDescription = styled.div`
  font-size: 14px;
  margin-top: 6px;
  color: ${({ theme }) => theme?.colors?.text?.tertiary || fallbackColors.text.tertiary};
`;

const ServicePrice = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  font-size: 18px;
`;

const InfoTag = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  background: ${({ theme }) => theme?.colors?.background?.tertiary || fallbackColors.background.tertiary};
  border-radius: 30px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  margin-top: 8px;
  margin-left: 8px;
  
  svg {
    margin-right: 4px;
    font-size: 12px;
  }
`;

// CTA Section
const CTASection = styled.section`
  padding: 100px 0;
  background: linear-gradient(135deg, #3A0CA3 0%, #4361EE 100%);
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(255,255,255,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E");
    opacity: 0.5;
  }
`;

const CTAContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
`;

const CTATitle = styled.h2`
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 20px;
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 2.5rem;
  }
`;

const CTADescription = styled.p`
  font-size: 1.25rem;
  opacity: 0.9;
  max-width: 600px;
  margin: 0 auto 40px;
  line-height: 1.7;
  
  @media (max-width: ${({ theme }) => theme?.breakpoints?.md || '768px'}) {
    font-size: 1.1rem;
  }
`;

const CTAButton = styled(Button)`
  background: white;
  color: ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main};
  padding: 16px 32px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
`;

// Add the missing SectionHeader component
const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 60px;
`;

const SectionSubtitle = styled.h3`
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 16px;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
`;

// Adding specific components for Implementation section
const ImplementationHeaderSubtitle = styled(HeaderSubtitle)`
  background: rgba(67, 97, 238, 0.1);
  color: ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main};
`;

const ImplementationTitle = styled(SectionTitle)`
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  
  span::after {
    background-color: rgba(76, 201, 240, 0.3);
  }
`;

// Adding specific components for À La Carte section
const ALaCarteTitle = styled(SectionTitle)`
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  
  span::after {
    background-color: rgba(76, 201, 240, 0.3);
  }
`;

const ALaCarteDescription = styled(SectionDescription)`
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
`;

// Define pricing tiers data
const pricingTiers = [
  {
    name: 'Essentials',
    description: 'For small to medium businesses looking to automate core marketing functions',
    featured: false,
    monthly: 2500,
    annual: 27000,
    priceCaption: 'monthly, or $27,000/year (save 10%)',
    featureGroups: [
      {
        title: 'Content Creation & Management',
        features: [
          { label: 'AI-powered content generation (up to 10 pieces/month)', included: true },
          { label: 'Basic content calendar automation', included: true },
          { label: 'Single-platform publishing integration', included: true },
        ]
      },
      {
        title: 'Social Media Management',
        features: [
          { label: 'Automated content generation for 2 platforms', included: true },
          { label: 'AI-driven posting schedule optimization', included: true },
          { label: 'Basic engagement monitoring', included: true },
        ]
      },
      {
        title: 'Email Marketing Automation',
        features: [
          { label: 'Dynamic content generation for email campaigns', included: true },
          { label: 'Basic send-time optimization', included: true },
          { label: 'A/B testing automation for subject lines', included: true },
        ]
      },
      {
        title: 'Analytics & Reporting',
        features: [
          { label: 'Automated weekly reports', included: true },
          { label: 'Basic anomaly detection', included: true },
          { label: 'Performance dashboards', included: true },
          { label: 'AI-generated natural language insights', included: false },
          { label: 'Predictive performance modeling', included: false },
        ]
      },
      {
        title: 'Support',
        features: [
          { label: 'Standard response time (24-48 hours)', included: true },
          { label: 'Knowledge base access', included: true },
          { label: 'Monthly webinars', included: true },
          { label: 'Dedicated implementation specialist', included: false },
          { label: 'Priority support', included: false },
        ]
      }
    ]
  },
  {
    name: 'Professional',
    description: 'For mid-sized businesses with established marketing operations',
    featured: true,
    monthly: 5000,
    annual: 54000,
    priceCaption: 'monthly, or $54,000/year (save 10%)',
    featureGroups: [
      {
        title: 'Content Creation & Management',
        features: [
          { label: 'Unlimited content generation', included: true },
          { label: 'Multi-channel content adaptation', included: true },
          { label: 'Advanced content optimization for SEO & conversions', included: true },
        ]
      },
      {
        title: 'Advanced Features',
        features: [
          { label: 'Customer Segmentation & Personalization', included: true },
          { label: 'SEO & Content Optimization', included: true },
          { label: 'PPC & Paid Advertising', included: true },
          { label: 'Dynamic micro-segmentation', included: true },
          { label: 'Automated keyword discovery', included: true },
          { label: 'AI-generated ad creative', included: true },
        ]
      },
      {
        title: 'Advanced Analytics',
        features: [
          { label: 'AI-generated natural language insights', included: true },
          { label: 'Predictive performance modeling', included: true },
          { label: 'Automated recommendation engine', included: true },
        ]
      },
      {
        title: 'Enhanced Support',
        features: [
          { label: 'Dedicated implementation specialist', included: true },
          { label: 'Priority support (12-hour response time)', included: true },
          { label: 'Quarterly strategy consultations', included: true },
          { label: '24/7 dedicated support', included: false },
          { label: 'Monthly executive briefings', included: false },
        ]
      }
    ]
  },
  {
    name: 'Enterprise',
    description: 'For large organizations with complex marketing ecosystems',
    featured: false,
    monthly: '10,000+',
    annual: 'Custom',
    priceCaption: 'monthly, custom annual agreements available',
    featureGroups: [
      {
        title: 'Full Marketing Pipeline Integration',
        features: [
          { label: 'All 10 marketing pipelines fully implemented', included: true },
          { label: 'Custom workflow design and optimization', included: true },
          { label: 'Enterprise system integrations', included: true },
        ]
      },
      {
        title: 'Advanced Customization',
        features: [
          { label: 'Custom ML model development', included: true },
          { label: 'Proprietary algorithm adaptation', included: true },
          { label: 'White-labeled dashboards and reports', included: true },
        ]
      },
      {
        title: 'Strategic Implementation',
        features: [
          { label: 'Dedicated implementation team', included: true },
          { label: 'Full marketing stack integration', included: true },
          { label: 'Custom training programs for your team', included: true },
        ]
      },
      {
        title: 'Premium Support',
        features: [
          { label: '24/7 dedicated support', included: true },
          { label: 'Monthly executive briefings', included: true },
          { label: 'Advanced security protocols', included: true },
          { label: 'Custom API development', included: true },
        ]
      }
    ]
  }
];

// Define implementation packages
const implementationPackages = [
  {
    name: 'Standard Implementation',
    description: 'Basic setup for smaller organizations',
    price: '$5,000',
    oneTime: true,
    timeline: '2-4 weeks',
    features: [
      'Basic setup and configuration',
      'Connection to existing platforms',
      'Standard onboarding for up to 5 users',
      'Implementation timeline: 2-4 weeks',
    ]
  },
  {
    name: 'Advanced Implementation',
    description: 'Comprehensive integration for established businesses',
    price: '$10,000',
    oneTime: true,
    timeline: '4-6 weeks',
    features: [
      'Comprehensive system integration',
      'Custom workflow configuration',
      'Detailed training for up to 10 users',
      'Implementation timeline: 4-6 weeks',
    ]
  },
  {
    name: 'Enterprise Implementation',
    description: 'Full-scale integration for complex organizations',
    price: '$25,000+',
    oneTime: true,
    timeline: '8-12 weeks',
    features: [
      'Full-scale enterprise integration',
      'Custom development work',
      'Comprehensive team training',
      'Process re-engineering consultation',
      'Implementation timeline: 8-12 weeks',
    ]
  }
];

// Define À La Carte pipeline options
const alaCartePipelines = [
  {
    name: 'Content Creation & Management',
    description: 'AI-powered content generation for all your marketing channels',
    monthlyPrice: '1,500',
    annualPrice: '1,350',
    monthlyNotes: 'Monthly plan',
    annualNotes: 'Save 10% annually'
  },
  {
    name: 'Customer Segmentation & Personalization',
    description: 'Dynamic micro-segmentation and personalized content delivery',
    monthlyPrice: '1,500',
    annualPrice: '1,350',
    monthlyNotes: 'Monthly plan',
    annualNotes: 'Save 10% annually'
  },
  {
    name: 'Social Media Management',
    description: 'AI-driven content creation and posting optimization for social platforms',
    monthlyPrice: '1,000',
    annualPrice: '900',
    monthlyNotes: 'Monthly plan',
    annualNotes: 'Save 10% annually'
  },
  {
    name: 'Email Marketing Automation',
    description: 'Dynamic email campaigns with send-time optimization and A/B testing',
    monthlyPrice: '1,000',
    annualPrice: '900',
    monthlyNotes: 'Monthly plan',
    annualNotes: 'Save 10% annually'
  },
  {
    name: 'SEO & Content Optimization',
    description: 'Automated keyword discovery and content optimization for search',
    monthlyPrice: '1,000',
    annualPrice: '900',
    monthlyNotes: 'Monthly plan',
    annualNotes: 'Save 10% annually'
  },
  {
    name: 'PPC & Paid Advertising',
    description: 'AI-generated ad creative and dynamic bid management',
    monthlyPrice: '1,200',
    annualPrice: '1,080',
    monthlyNotes: 'Monthly plan',
    annualNotes: 'Save 10% annually'
  }
];

const Pricing = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  
  const toggleBilling = () => {
    setIsAnnual(!isAnnual);
  };
  
  return (
    <>
      <HeaderSection>
        <FloatingIcon className="dollar">
          <FiDollarSign />
        </FloatingIcon>
        <FloatingIcon className="percent">
          <FiPercent />
        </FloatingIcon>
        <FloatingIcon className="refresh">
          <FiRefreshCw />
        </FloatingIcon>
        
        <HeaderContent>
        <Container>
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <HeaderSubtitle>Simple & Transparent</HeaderSubtitle>
              <SectionTitle>
                AI-Powered Marketing <span>Pricing Plans</span>
              </SectionTitle>
          <SectionDescription>
            Transparent, value-based pricing that scales with your business needs. Achieve immediate ROI with our AI-powered marketing automation.
          </SectionDescription>
          
          <ContactInfo>
            Get customized pricing tailored to your specific business needs.
            <br />
            <ContactHighlight>Contact our sales team for detailed information.</ContactHighlight>
          </ContactInfo>
            </motion.div>
        </Container>
        </HeaderContent>
      </HeaderSection>
      
      <PricingSection>
        <Container>
          <TiersContainer>
            {pricingTiers.map((tier, index) => (
              <PricingTier 
                key={index}
                featured={tier.featured}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <TierHeader>
                  <TierName featured={tier.featured}>{tier.name}</TierName>
                  <TierDescription>{tier.description}</TierDescription>
                </TierHeader>
                
                <TierContent>
                  <PriceUnit tier={tier} isAnnual={isAnnual} />
                
                {tier.featureGroups.map((group, groupIndex) => (
                  <FeatureGroup key={groupIndex}>
                      <FeatureGroupName>{group.title}</FeatureGroupName>
                      <FeatureList>
                      {group.features.map((feature, featureIndex) => (
                          <Feature key={featureIndex}>
                            <FeatureIcon included={feature.included}>
                          {feature.included ? <FiCheck size={18} /> : <FiX size={18} />}
                            </FeatureIcon>
                            <FeatureText>{feature.label}</FeatureText>
                          </Feature>
                      ))}
                      </FeatureList>
                  </FeatureGroup>
                ))}
                </TierContent>
                
                <TierFooter>
                  <TierButton 
                    featured={tier.featured}
                  as={Link}
                  to={tier.featured ? '/contact' : '/contact/sales'}
                >
                  {tier.featured ? 'Get Started' : 'Contact Sales'}
                  </TierButton>
                </TierFooter>
              </PricingTier>
            ))}
          </TiersContainer>
        </Container>
      </PricingSection>
      
      <ImplementationHeaderSection>
        <Container>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <ImplementationHeaderSubtitle>Get Started Quickly</ImplementationHeaderSubtitle>
            <ImplementationTitle>
              Implementation <span>Plans</span>
            </ImplementationTitle>
            <ImplementationHeaderDescription>
              Choose the right implementation package for your business needs. Our expert team will guide you through the entire process.
            </ImplementationHeaderDescription>
          </motion.div>
        </Container>
      </ImplementationHeaderSection>
      
      <ImplementationSection>
        <Container>
          <ImplementationGrid>
            {implementationPackages.map((pkg, index) => (
              <ImplementationCard 
                key={index}
                index={index}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.15 }}
              >
                <CardHeader>
                  <PackageName packageType={pkg.name.split(' ')[0]}>
                    {pkg.name}
                  </PackageName>
                  <PackageDescription>{pkg.description}</PackageDescription>
                </CardHeader>
                
                <CardContent>
                  <TimelineIndicator packageType={pkg.name.split(' ')[0]}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="12" cy="12" r="10"></circle>
                      <polyline points="12 6 12 12 16 14"></polyline>
                    </svg>
                    {pkg.timeline} implementation
                  </TimelineIndicator>
                
                  <PackageFeatures>
                    {pkg.features.map((feature, featureIndex) => (
                        <PackageFeature 
                          key={featureIndex} 
                          packageType={pkg.name.split(' ')[0]} 
                          index={featureIndex}
                        >
                          {feature}
                        </PackageFeature>
                    ))}
                  </PackageFeatures>
                </CardContent>
                
                <CardFooter>
                  <PackageButton 
                    packageType={pkg.name.split(' ')[0]}
                  as={Link}
                  to="/contact/sales"
                  >
                    Get Started
                  </PackageButton>
                </CardFooter>
              </ImplementationCard>
            ))}
          </ImplementationGrid>
        </Container>
      </ImplementationSection>
      
      <ALaCarteSection>
        <Container>
          <ALaCarteHeader>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <ALaCarteTitle>À La Carte Marketing Services</ALaCarteTitle>
              <ALaCarteDescription>
                Need specific marketing pipelines or services? Choose from our à la carte options to customize your marketing strategy.
              </ALaCarteDescription>
            </motion.div>
          </ALaCarteHeader>
          
          <ALaCarteGrid>
            <ALaCarteTable
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <TableHeader>
                <TableHeading>Available Services</TableHeading>
              </TableHeader>
              
              {alaCartePipelines.map((pipeline, index) => (
                <TableRow 
                  key={index} 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 + index * 0.05 }}
                >
                  <TableCell>
                    <ServiceName>
                      {pipeline.icon && pipeline.icon}
                      {!pipeline.icon && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.5L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.5zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
                      </svg>}
                      {pipeline.name}
                    </ServiceName>
                    <ServiceDescription>
                      {pipeline.description}
                    </ServiceDescription>
                  </TableCell>
                </TableRow>
              ))}
            </ALaCarteTable>
          </ALaCarteGrid>
        </Container>
      </ALaCarteSection>
      
      <CTASection>
        <CTAContent>
        <Container>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
          <CTATitle>Ready to Transform Your Marketing?</CTATitle>
          <CTADescription>
                Schedule a consultation with our experts to explore how our AI-powered marketing solutions can help your business grow.
          </CTADescription>
              <CTAButton as={Link} to="/contact/sales">
                Schedule a Consultation
          </CTAButton>
            </motion.div>
        </Container>
        </CTAContent>
      </CTASection>
    </>
  );
};

export default Pricing; 