import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiBarChart2, FiArrowRight, FiFilter, FiCheck } from 'react-icons/fi';
import Container from '../components/Container';
import Button from '../components/Button';

// Hero Section
const HeroSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[32]} 0 ${theme.spacing[16]}`};
  background: ${({ theme }) => theme.colors.gradients.primary};
  text-align: center;
  color: white;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => `${theme.spacing[24]} 0 ${theme.spacing[12]}`};
  }
`;

const HeroTitle = styled.h1`
  color: white;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const HeroDescription = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 0 auto;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.typography.fontSize.lg};
  }
`;

// Filter Section
const FilterSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[8]} 0`};
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing[4]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
  }
`;

const FilterLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing[3]};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  
  svg {
    margin-right: ${({ theme }) => theme.spacing[2]};
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const FilterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing[2]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: ${({ theme }) => theme.spacing[2]};
    width: 100%;
    
    &::-webkit-scrollbar {
      height: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.border.main};
      border-radius: ${({ theme }) => theme.borders.radius.full};
    }
  }
`;

const FilterButton = styled.button`
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
  border-radius: ${({ theme }) => theme.borders.radius.full};
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  border: none;
  background-color: ${({ active, theme }) => 
    active ? theme.colors.primary.main : theme.colors.background.secondary};
  color: ${({ active, theme }) => 
    active ? theme.colors.primary.contrast : theme.colors.text.primary};
  white-space: nowrap;
  
  &:hover {
    background-color: ${({ active, theme }) => 
      active ? theme.colors.primary.dark : theme.colors.background.tertiary};
  }
`;

// Case Studies Section
const CaseStudiesSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const CaseStudiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing[6]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const CaseStudyCard = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.sm};
  transition: transform ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease},
              box-shadow ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: ${({ theme }) => theme.shadows.md};
  }
`;

const CaseStudyImage = styled.div`
  height: 200px;
  position: relative;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  }
  
  ${CaseStudyCard}:hover & img {
    transform: scale(1.05);
  }
`;

const CaseStudyCategory = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing[4]};
  left: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[3]}`};
  background-color: ${({ theme, category }) => {
    switch (category) {
      case 'Content Creation':
        return theme.colors.primary.main;
      case 'Social Media':
        return theme.colors.secondary.main;
      case 'Email Marketing':
        return theme.colors.accent.coral;
      case 'SEO':
        return theme.colors.accent.purple;
      case 'Paid Advertising':
        return theme.colors.accent.yellow;
      default:
        return theme.colors.primary.main;
    }
  }};
  color: white;
  border-radius: ${({ theme }) => theme.borders.radius.full};
  font-size: ${({ theme }) => theme.typography.fontSize.xs};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
`;

const CaseStudyContent = styled.div`
  padding: ${({ theme }) => theme.spacing[6]};
`;

const CaseStudyClient = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const CaseStudyClientName = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

const CaseStudyClientIndustry = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
`;

const CaseStudyResults = styled.div`
  margin: ${({ theme }) => `${theme.spacing[4]} 0`};
`;

const CaseStudyResultsTitle = styled.h4`
  font-size: ${({ theme }) => theme.typography.fontSize.base};
  margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const CaseStudyResultsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const CaseStudyResultItem = styled.div`
  flex: 1;
  min-width: 120px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 100px;
  }
`;

const CaseStudyResultValue = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSize['2xl']};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.colors.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing[1]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.typography.fontSize.xl};
  }
`;

const CaseStudyResultLabel = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const CaseStudyFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing[4]};
  padding-top: ${({ theme }) => theme.spacing[4]};
  border-top: 1px solid ${({ theme }) => theme.colors.border.light};
`;

const CaseStudyIndustry = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
  display: flex;
  align-items: center;
`;

const CaseStudyLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  
  svg {
    margin-left: ${({ theme }) => theme.spacing[1]};
    transition: transform ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  }
  
  &:hover svg {
    transform: translateX(4px);
  }
`;

// Featured Case Study Section
const FeaturedSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

const FeaturedCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  background-color: ${({ theme }) => theme.colors.background.card};
  box-shadow: ${({ theme }) => theme.shadows.md};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

const FeaturedImage = styled.div`
  height: 100%;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 300px;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const FeaturedContent = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => theme.spacing[6]};
  }
`;

const FeaturedLabel = styled.div`
  display: inline-block;
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[3]}`};
  background-color: ${({ theme }) => `${theme.colors.primary.main}15`};
  color: ${({ theme }) => theme.colors.primary.main};
  border-radius: ${({ theme }) => theme.borders.radius.full};
  font-size: ${({ theme }) => theme.typography.fontSize.xs};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const FeaturedTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const FeaturedIndustry = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const FeaturedDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  font-size: ${({ theme }) => theme.typography.fontSize.lg};
`;

const FeaturedResultsList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const FeaturedResultItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const FeaturedResultIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: ${({ theme }) => theme.borders.radius.full};
  background-color: ${({ theme }) => `${theme.colors.secondary.main}15`};
  color: ${({ theme }) => theme.colors.secondary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing[3]};
  flex-shrink: 0;
`;

const FeaturedResultText = styled.p`
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
`;

const FeaturedButton = styled(Button)`
  align-self: flex-start;
`;

// CTA Section
const CTASection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background: ${({ theme }) => theme.colors.gradients.secondary};
  color: white;
  text-align: center;
`;

const CTATitle = styled.h2`
  color: white;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const CTADescription = styled.p`
  color: rgba(255, 255, 255, 0.9);
  font-size: ${({ theme }) => theme.typography.fontSize.lg};
  max-width: 800px;
  margin: 0 auto ${({ theme }) => theme.spacing[8]};
`;

const CTAButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[4]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
  }
`;

const CTAButton = styled(Button)`
  background-color: white;
  color: ${({ theme }) => theme.colors.primary.main};
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

// Case study data
const caseStudies = [
  {
    id: 1,
    client: 'TechCorp Inc.',
    industry: 'SaaS / Technology',
    category: 'Content Creation',
    image: 'https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
    results: [
      { value: '320%', label: 'More content' },
      { value: '78%', label: 'Cost reduction' }
    ],
    slug: 'techcorp-case-study'
  },
  {
    id: 2,
    client: 'RetailX',
    industry: 'E-commerce / Retail',
    category: 'Email Marketing',
    image: 'https://images.unsplash.com/photo-1534452203293-494d7ddbf7e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1772&q=80',
    results: [
      { value: '152%', label: 'Open rate increase' },
      { value: '89%', label: 'Revenue growth' }
    ],
    slug: 'retailx-case-study'
  },
  {
    id: 3,
    client: 'Global Finance',
    industry: 'Financial Services',
    category: 'Social Media',
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1715&q=80',
    results: [
      { value: '210%', label: 'Engagement increase' },
      { value: '46%', label: 'Time saved' }
    ],
    slug: 'global-finance-case-study'
  },
  {
    id: 4,
    client: 'MediHealth',
    industry: 'Healthcare',
    category: 'SEO',
    image: 'https://images.unsplash.com/photo-1579684385127-1ef15d508118?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80',
    results: [
      { value: '87%', label: 'Organic traffic growth' },
      { value: '65%', label: 'Conversion rate' }
    ],
    slug: 'medihealth-case-study'
  },
  {
    id: 5,
    client: 'Urban Dwellers',
    industry: 'Real Estate',
    category: 'Paid Advertising',
    image: 'https://images.unsplash.com/photo-1560520031-3a4dc4e9de0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1773&q=80',
    results: [
      { value: '43%', label: 'Lower CPA' },
      { value: '115%', label: 'ROAS improvement' }
    ],
    slug: 'urban-dwellers-case-study'
  },
  {
    id: 6,
    client: 'EcoFriends',
    industry: 'Non-profit / Environmental',
    category: 'Content Creation',
    image: 'https://images.unsplash.com/photo-1567177662154-dfeb4c93b6ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
    results: [
      { value: '240%', label: 'Donation increase' },
      { value: '67%', label: 'Volunteer signups' }
    ],
    slug: 'ecofriends-case-study'
  }
];

const featuredCaseStudy = {
  client: 'GrowthHub Agency',
  industry: 'Marketing Agency',
  category: 'Full Marketing Pipeline Integration',
  image: 'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
  description: 'GrowthHub, a digital marketing agency serving 50+ clients, transformed their operations by implementing all 10 of BetterMarkt\'s AI marketing pipelines. The result was a radical increase in efficiency and effectiveness.',
  results: [
    '78% reduction in time spent on routine marketing tasks',
    '3x increase in client capacity without adding staff',
    '92% client satisfaction rating after implementation',
    'ROI of 412% in the first year'
  ],
  slug: 'growthhub-case-study'
};

// Filter categories
const industries = [
  'All Industries',
  'Technology',
  'E-commerce',
  'Financial',
  'Healthcare',
  'Real Estate',
  'Non-profit'
];

const categories = [
  'All Categories',
  'Content Creation',
  'Email Marketing',
  'Social Media',
  'SEO',
  'Paid Advertising'
];

const CaseStudies = () => {
  const [activeIndustry, setActiveIndustry] = useState('All Industries');
  const [activeCategory, setActiveCategory] = useState('All Categories');
  
  const filteredCaseStudies = caseStudies.filter(study => {
    // Filter by industry
    const industryMatch = activeIndustry === 'All Industries' || 
      study.industry.toLowerCase().includes(activeIndustry.toLowerCase());
    
    // Filter by category
    const categoryMatch = activeCategory === 'All Categories' || 
      study.category === activeCategory;
    
    return industryMatch && categoryMatch;
  });
  
  return (
    <>
      <HeroSection>
        <Container>
          <HeroTitle>Our Success Stories</HeroTitle>
          <HeroDescription>
            Discover how organizations across industries have transformed their marketing with BetterMarkt's AI-powered pipelines.
          </HeroDescription>
        </Container>
      </HeroSection>
      
      <FilterSection>
        <Container>
          <FiltersContainer>
            <FilterGroup>
              <FilterLabel>
                <FiFilter />
                Industry:
              </FilterLabel>
              <FilterButtons>
                {industries.map((industry, index) => (
                  <FilterButton
                    key={index}
                    active={activeIndustry === industry}
                    onClick={() => setActiveIndustry(industry)}
                  >
                    {industry}
                  </FilterButton>
                ))}
              </FilterButtons>
            </FilterGroup>
            
            <FilterGroup>
              <FilterLabel>
                <FiFilter />
                Category:
              </FilterLabel>
              <FilterButtons>
                {categories.map((category, index) => (
                  <FilterButton
                    key={index}
                    active={activeCategory === category}
                    onClick={() => setActiveCategory(category)}
                  >
                    {category}
                  </FilterButton>
                ))}
              </FilterButtons>
            </FilterGroup>
          </FiltersContainer>
        </Container>
      </FilterSection>
      
      <CaseStudiesSection>
        <Container>
          <CaseStudiesGrid>
            {filteredCaseStudies.map((study, index) => (
              <CaseStudyCard
                key={study.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <CaseStudyImage>
                  <img src={study.image} alt={study.client} />
                  <CaseStudyCategory category={study.category}>{study.category}</CaseStudyCategory>
                </CaseStudyImage>
                <CaseStudyContent>
                  <CaseStudyClient>
                    <CaseStudyClientName>{study.client}</CaseStudyClientName>
                    <CaseStudyClientIndustry>{study.industry}</CaseStudyClientIndustry>
                  </CaseStudyClient>
                  
                  <CaseStudyResults>
                    <CaseStudyResultsTitle>Key Results:</CaseStudyResultsTitle>
                    <CaseStudyResultsList>
                      {study.results.map((result, index) => (
                        <CaseStudyResultItem key={index}>
                          <CaseStudyResultValue>{result.value}</CaseStudyResultValue>
                          <CaseStudyResultLabel>{result.label}</CaseStudyResultLabel>
                        </CaseStudyResultItem>
                      ))}
                    </CaseStudyResultsList>
                  </CaseStudyResults>
                  
                  <CaseStudyFooter>
                    <CaseStudyIndustry>
                      <FiBarChart2 style={{ marginRight: '8px' }} />
                      {study.industry}
                    </CaseStudyIndustry>
                    <CaseStudyLink to={`/case-studies/${study.slug}`}>
                      Read case study <FiArrowRight />
                    </CaseStudyLink>
                  </CaseStudyFooter>
                </CaseStudyContent>
              </CaseStudyCard>
            ))}
          </CaseStudiesGrid>
        </Container>
      </CaseStudiesSection>
      
      <FeaturedSection>
        <Container>
          <FeaturedCard>
            <FeaturedImage>
              <img src={featuredCaseStudy.image} alt={featuredCaseStudy.client} />
            </FeaturedImage>
            <FeaturedContent>
              <FeaturedLabel>Featured Case Study</FeaturedLabel>
              <FeaturedTitle>{featuredCaseStudy.client}</FeaturedTitle>
              <FeaturedIndustry>{featuredCaseStudy.industry}</FeaturedIndustry>
              <FeaturedDescription>{featuredCaseStudy.description}</FeaturedDescription>
              
              <FeaturedResultsList>
                {featuredCaseStudy.results.map((result, index) => (
                  <FeaturedResultItem key={index}>
                    <FeaturedResultIcon>
                      <FiCheck />
                    </FeaturedResultIcon>
                    <FeaturedResultText>{result}</FeaturedResultText>
                  </FeaturedResultItem>
                ))}
              </FeaturedResultsList>
              
              <FeaturedButton
                as={Link}
                to={`/case-studies/${featuredCaseStudy.slug}`}
              >
                Read Full Case Study
              </FeaturedButton>
            </FeaturedContent>
          </FeaturedCard>
        </Container>
      </FeaturedSection>
      
      <CTASection>
        <Container>
          <CTATitle>Ready for Your Success Story?</CTATitle>
          <CTADescription>
            Join these organizations in transforming your marketing operations with AI-powered pipelines. Get started today with BetterMarkt.
          </CTADescription>
          <CTAButtons>
            <CTAButton
              $size="large"
              as={Link}
              to="/contact/sales"
            >
              Talk to Our Experts
            </CTAButton>
            <CTAButton
              $size="large"
              as={Link}
              to="/pricing"
              $variant="outline"
            >
              View Pricing
            </CTAButton>
          </CTAButtons>
        </Container>
      </CTASection>
    </>
  );
};

export default CaseStudies; 