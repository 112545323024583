import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiLayers, FiUsers, FiMessageCircle, FiMail, FiSearch, FiDollarSign, 
  FiPieChart, FiHeadphones, FiTrendingUp, FiStar, FiArrowRight } from 'react-icons/fi';
import Container from '../components/Container';
import Button from '../components/Button';
import AnimatedSection from '../components/AnimatedSection';
import { ParallaxSection } from '../components/ParallaxEffect';

// Define fallback colors in case theme is not available
const fallbackColors = {
  primary: {
    main: '#3A0CA3',
    light: '#4CC9F0',
    dark: '#270980',
    contrast: '#FFFFFF'
  },
  text: {
    primary: '#1A1A2E',
    secondary: '#4A4A68',
    white: '#FFFFFF'
  },
  background: {
    default: '#FFFFFF',
    light: '#F8FAFC',
    dark: '#1A1A2E',
    primary: '#FFFFFF',
    secondary: '#F8FAFC',
    tertiary: '#F1F5F9',
    card: '#FFFFFF'
  },
  accent: {
    coral: '#F97316',
    purple: '#A855F7',
    teal: '#14B8A6'
  },
  border: {
    light: '#E2E8F0'
  }
};

// Header Section
const HeaderSection = styled.section`
  position: relative;
  padding: 120px 0 100px;
  background: linear-gradient(135deg, #3A0CA3 0%, #4361EE 100%);
  text-align: center;
  color: white;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(255,255,255,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E");
    opacity: 0.5;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 100px 0 80px;
  }
`;

const HeaderContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1000px;
  margin: 0 auto;
`;

const HeaderSubtitle = styled.div`
  display: inline-block;
  margin-bottom: 16px;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.9);
`;

const HeaderTitle = styled.h1`
  color: white;
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 24px;
  line-height: 1.2;
  
  span {
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 5px;
      width: 100%;
      height: 12px;
      background-color: rgba(76, 201, 240, 0.3);
      z-index: -1;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2.75rem;
  }
`;

const HeaderDescription = styled.p`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 720px;
  margin: 0 auto 40px;
  line-height: 1.7;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.1rem;
  }
`;

const SearchBar = styled.div`
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  padding: 6px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

const SearchInput = styled.input`
  flex: 1;
  background: transparent;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  color: white;
  outline: none;
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const SearchButton = styled.button`
  background: white;
  color: ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main};
  font-weight: 600;
  padding: 12px 24px;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

// Filter Section
const FilterSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[8]} 0`};
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[2]};
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
  border-radius: ${({ theme }) => theme.borders.radius.full};
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  border: none;
  background-color: ${({ active, theme }) => 
    active ? theme.colors.primary.main : theme.colors.background.secondary};
  color: ${({ active, theme }) => 
    active ? theme.colors.primary.contrast : theme.colors.text.primary};
  
  &:hover {
    background-color: ${({ active, theme }) => 
      active ? theme.colors.primary.dark : theme.colors.background.tertiary};
  }
`;

// Services Section
const ServicesSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 30px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const ServiceCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.04);
  padding: 30px;
  height: 100%;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
    border-color: rgba(0, 0, 0, 0.08);
  }
`;

const ServiceHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 28px;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 50px;
    height: 3px;
    background: ${({ color }) => color || 'linear-gradient(45deg, #3A0CA3, #4361EE)'};
    border-radius: 3px;
  }
`;

const ServiceIconContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background: ${({ color }) => color || 'linear-gradient(45deg, #3A0CA3, #4361EE)'};
  color: white;
  font-size: 26px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 16px ${({ color }) => color ? `${color}33` : 'rgba(58, 12, 163, 0.2)'};
  
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: inherit;
    filter: blur(12px);
    opacity: 0.4;
    z-index: 0;
  }
  
  svg {
    position: relative;
    z-index: 1;
  }
`;

const ServiceInfo = styled.div`
  flex: 1;
`;

const ServiceTag = styled.div`
  display: inline-block;
  padding: 6px 12px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: ${({ category, theme }) => {
    switch (category) {
      case 'Content':
        return `${theme?.colors?.primary?.main || fallbackColors.primary.main}15`;
      case 'Social':
        return `${theme?.colors?.accent?.purple || fallbackColors.accent.purple}15`;
      case 'SEO':
        return `${theme?.colors?.primary?.light || fallbackColors.primary.light}15`;
      case 'Analytics':
        return `${theme?.colors?.accent?.coral || fallbackColors.accent.coral}15`;
      case 'Advertising':
        return `${theme?.colors?.primary?.dark || fallbackColors.primary.dark}15`;
      case 'Campaigns':
        return `${theme?.colors?.accent?.teal || fallbackColors.accent.coral}15`;
      case 'Engagement':
        return `${theme?.colors?.primary?.light || fallbackColors.primary.light}15`;
      default:
        return `${theme?.colors?.primary?.main || fallbackColors.primary.main}15`;
    }
  }};
  color: ${({ category, theme }) => {
    switch (category) {
      case 'Content':
        return theme?.colors?.primary?.main || fallbackColors.primary.main;
      case 'Social':
        return theme?.colors?.accent?.purple || fallbackColors.accent.purple;
      case 'SEO':
        return theme?.colors?.primary?.light || fallbackColors.primary.light;
      case 'Analytics':
        return theme?.colors?.accent?.coral || fallbackColors.accent.coral;
      case 'Advertising':
        return theme?.colors?.primary?.dark || fallbackColors.primary.dark;
      case 'Campaigns':
        return theme?.colors?.accent?.teal || fallbackColors.accent.coral;
      case 'Engagement':
        return theme?.colors?.primary?.light || fallbackColors.primary.light;
      default:
        return theme?.colors?.primary?.main || fallbackColors.primary.main;
    }
  }};
`;

const ServiceTitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  line-height: 1.3;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 20px;
  }
`;

const ServiceDescription = styled.p`
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  margin: 20px 0;
  line-height: 1.7;
  font-size: 15px;
`;

const ServiceFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 28px 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const ServiceFeature = styled.li`
  position: relative;
  padding-left: 32px;
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  font-size: 15px;
  animation: fadeIn 0.5s ease-out forwards;
  animation-delay: ${props => props.index * 0.1}s;
  opacity: 0;
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    background-color: ${({ theme, color }) => `${color || theme?.colors?.primary?.main || fallbackColors.primary.main}15`};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &::after {
    content: '✓';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    color: ${({ theme, color }) => color || theme?.colors?.primary?.main || fallbackColors.primary.main};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
  }
`;

const ServiceLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  margin-top: auto;
  color: ${({ theme, color }) => color || theme?.colors?.primary?.main || fallbackColors.primary.main};
  text-decoration: none;
  position: relative;
  padding-bottom: 3px;
  font-size: 15px;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: ${({ theme, color }) => color || theme?.colors?.primary?.main || fallbackColors.primary.main};
    transition: width 0.3s ease;
  }
  
  svg {
    margin-left: 8px;
    transition: transform 0.3s ease;
  }
  
  &:hover {
    &::after {
      width: 100%;
    }
    
    svg {
      transform: translateX(4px);
    }
  }
`;

// Comparison Section
const ComparisonSection = styled.section`
  padding: 120px 0;
  background-color: ${({ theme }) => theme?.colors?.background?.secondary || fallbackColors.background.secondary};
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.03'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.8;
  }
`;

const SectionHeader = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto 60px;
  position: relative;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme?.colors?.text?.primary || fallbackColors.text.primary};
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: linear-gradient(90deg, 
      ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main}, 
      ${({ theme }) => theme?.colors?.primary?.light || fallbackColors.primary.light}
    );
    border-radius: 2px;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2rem;
  }
`;

const SectionDescription = styled.p`
  color: ${({ theme }) => theme?.colors?.text?.secondary || fallbackColors.text.secondary};
  font-size: 1.125rem;
  line-height: 1.6;
`;

const ComparisonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 0;
`;

const ComparisonItem = styled(motion.div)`
  background: white;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.04);
  transition: all 0.4s ease;
  margin-bottom: 20px;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 100%;
    height: 20px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.03), transparent);
    pointer-events: none;
  }
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    transform: none;
  }
`;

const ComparisonHeader = styled.div`
  background: ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main};
  color: white;
  padding: 24px 30px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1));
  }
`;

const AspectTitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
`;

const AspectIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.15);
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  flex-shrink: 0;
`;

const ComparisonContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  
  &::after {
    content: 'VS';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background: ${({ theme }) => theme?.colors?.primary?.light || fallbackColors.primary.light};
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 14px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 5;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    
    &::after {
      top: calc(50% - 25px);
      width: 40px;
      height: 40px;
      font-size: 12px;
    }
  }
`;

const ComparisonColumn = styled.div`
  padding: 40px 30px;
  position: relative;
  transition: all 0.3s ease;
  
  &:first-child {
    background: rgba(0, 0, 0, 0.02);
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      border-right: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
  
  &:last-child {
    background: linear-gradient(to bottom right, rgba(67, 97, 238, 0.03), rgba(76, 201, 240, 0.03));
  }
`;

const ColumnLabel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 2px solid ${({ type, theme }) => 
    type === 'ai' 
      ? `${theme?.colors?.primary?.main || fallbackColors.primary.main}30`
      : 'rgba(0, 0, 0, 0.05)'
  };
`;

const LabelIcon = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 6px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex-shrink: 0;
  background: ${({ type, theme }) => 
    type === 'ai' 
      ? `${theme?.colors?.primary?.main || fallbackColors.primary.main}15`
      : 'rgba(0, 0, 0, 0.05)'
  };
  color: ${({ type, theme }) => 
    type === 'ai' 
      ? theme?.colors?.primary?.main || fallbackColors.primary.main
      : theme?.colors?.text?.secondary || fallbackColors.text.secondary
  };
`;

const LabelText = styled.span`
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${({ type, theme }) => 
    type === 'ai' 
      ? theme?.colors?.primary?.main || fallbackColors.primary.main
      : theme?.colors?.text?.secondary || fallbackColors.text.secondary
  };
`;

const ComparisonPoints = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const ComparisonPoint = styled.li`
  position: relative;
  padding-left: 30px;
  color: ${({ type, theme }) => 
    type === 'ai' 
      ? theme?.colors?.text?.primary || fallbackColors.text.primary
      : theme?.colors?.text?.secondary || fallbackColors.text.secondary
  };
  font-size: 15px;
  display: flex;
  align-items: flex-start;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${({ type, theme }) => 
      type === 'ai' 
        ? `${theme?.colors?.primary?.main || fallbackColors.primary.main}15`
        : 'rgba(0, 0, 0, 0.05)'
    };
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &::after {
    content: ${({ type }) => type === 'ai' ? '"✓"' : '"✗"'};
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    color: ${({ type, theme }) => 
      type === 'ai' 
        ? theme?.colors?.primary?.main || fallbackColors.primary.main
        : theme?.colors?.text?.secondary || fallbackColors.text.secondary
    };
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
  }
`;

// CTA Section
const CTASection = styled.section`
  padding: 100px 0;
  background: linear-gradient(135deg, #3A0CA3 0%, #4361EE 100%);
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
  
  &::before, &::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    opacity: 0.1;
    background: white;
  }
  
  &::before {
    top: -150px;
    left: -150px;
  }
  
  &::after {
    bottom: -150px;
    right: -150px;
  }
`;

const CTATitle = styled.h2`
  color: white;
  font-size: 2.75rem;
  font-weight: 800;
  margin-bottom: 24px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2.25rem;
  }
`;

const CTADescription = styled.p`
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
`;

const CTAButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 300px;
    margin: 0 auto;
  }
`;

const CTAButton = styled(Button)`
  position: relative;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;
  letter-spacing: 0.5px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2), transparent);
    transform: translateX(-100%);
    transition: transform 0.6s ease;
    z-index: -1;
  }
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.25);
    
    &::before {
      transform: translateX(100%);
    }
  }
  
  &:active {
    transform: translateY(-2px);
  }
  
  svg {
    margin-left: 8px;
    font-size: 18px;
    transition: transform 0.3s ease;
  }
  
  &:hover svg {
    transform: translateX(3px);
  }
  
  /* Primary button (white) */
  &:first-child {
    background-color: white;
    color: ${({ theme }) => theme?.colors?.primary?.main || fallbackColors.primary.main};
    border: none;
    
    &:hover {
      background-color: #f8f8f8;
    }
  }
  
  /* Secondary button (outline) */
  &:last-child {
    background-color: transparent;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.6);
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: white;
    }
  }
`;

// Service data
const services = [
  {
    id: 1,
    title: 'Content Creation & Management',
    description: 'Automate content creation across all formats with AI that optimizes for SEO and conversions in real-time.',
    category: 'Content',
    mainCategory: 'Content & SEO',
    icon: <FiLayers />,
    color: 'linear-gradient(45deg, #0EA5E9, #38BDF8)',
    features: [
      'AI powered content generation for all formats',
      'AI content optimization for SEO and conversions',
      'Automated content calendars and scheduling',
      'Content refresh automation based on performance',
      'Multi-channel content adaptation'
    ],
    link: '/services/content-creation'
  },
  {
    id: 2,
    title: 'Customer Segmentation & Personalization',
    description: 'Leverage ML-driven customer segmentation that goes beyond traditional demographics for precision targeting.',
    category: 'Analytics',
    mainCategory: 'Data & Analytics',
    icon: <FiUsers />,
    color: 'linear-gradient(45deg, #F97316, #FDBA74)',
    features: [
      'Dynamic micro-segmentation based on behavior',
      'Predictive personalization anticipating needs',
      'Automated personalized content delivery at scale',
      'Self-optimizing customer journeys',
      'Cross-channel personalization management'
    ],
    link: '/services/personalization'
  },
  {
    id: 3,
    title: 'Social Media Management',
    description: 'Intelligent social media automation with platform-specific content generation, optimal posting times, and AI-driven engagement.',
    category: 'Social',
    mainCategory: 'Audience Engagement',
    icon: <FiMessageCircle />,
    color: 'linear-gradient(45deg, #10B981, #34D399)',
    features: [
      'Automated content generation for each platform',
      'Intelligent posting schedule optimization',
      'AI community management for engagement',
      'Trend detection and automatic content creation',
      'Crisis detection and sentiment monitoring'
    ],
    link: '/services/social-media'
  },
  {
    id: 4,
    title: 'Email Marketing Automation',
    description: 'Next-generation email marketing with dynamic content generation, predictive send time optimization, and behavior-based targeting.',
    category: 'Campaigns',
    mainCategory: 'Paid Marketing',
    icon: <FiMail />,
    color: 'linear-gradient(45deg, #0EA5E9, #38BDF8)',
    features: [
      'Dynamic email content generation at scale',
      'Individual-level send time optimization',
      'Automated subject line creation and testing',
      'Smart segmentation for targeted campaigns',
      'Predictive churn prevention'
    ],
    link: '/services/email-marketing'
  },
  {
    id: 5,
    title: 'SEO & Content Optimization',
    description: 'Continuous SEO improvement with automated keyword discovery, real-time content optimization, and technical issue detection.',
    category: 'SEO',
    mainCategory: 'Content & SEO',
    icon: <FiSearch />,
    color: 'linear-gradient(45deg, #10B981, #34D399)',
    features: [
      'Automated keyword discovery and tracking',
      'Real-time content optimization suggestions',
      'Technical SEO monitoring and resolution',
      'Predictive SEO modeling for ranking changes',
      'Competitive gap analysis for content planning'
    ],
    link: '/services/seo'
  },
  {
    id: 6,
    title: 'PPC & Paid Advertising',
    description: 'AI-driven paid advertising management with automated campaign generation, dynamic bid adjustments, and predictive budget allocation.',
    category: 'Advertising',
    mainCategory: 'Paid Marketing',
    icon: <FiDollarSign />,
    color: 'linear-gradient(45deg, #F97316, #FDBA74)',
    features: [
      'Automated campaign creation based on objectives',
      'Dynamic bid management in real-time',
      'AI-generated ad creative customized by platform',
      'Predictive budget allocation across channels',
      'Competitor strategy analysis and insights'
    ],
    link: '/services/paid-advertising'
  },
  {
    id: 7,
    title: 'Analytics & Reporting',
    description: 'Unified marketing analytics with automated data collection, AI-generated reports, and predictive performance modeling.',
    category: 'Analytics',
    mainCategory: 'Data & Analytics',
    icon: <FiPieChart />,
    color: 'linear-gradient(45deg, #0EA5E9, #38BDF8)',
    features: [
      'Automated data integration across platforms',
      'Natural language insights from marketing data',
      'Anomaly detection for significant changes',
      'Predictive analytics for future performance',
      'Automated optimization recommendations'
    ],
    link: '/services/analytics'
  },
  {
    id: 8,
    title: 'Customer Support & Engagement',
    description: 'Intelligent customer service automation with sentiment-aware chatbots, voice bots, and proactive issue resolution.',
    category: 'Engagement',
    mainCategory: 'Audience Engagement',
    icon: <FiHeadphones />,
    color: 'linear-gradient(45deg, #10B981, #34D399)',
    features: [
      'Conversational AI for first-line support',
      'Sentiment-based interaction adjustments',
      'Automated resolution of common issues',
      'Smart escalation to appropriate human agents',
      'Proactive support outreach anticipating needs'
    ],
    link: '/services/customer-support'
  },
  {
    id: 9,
    title: 'Conversion Rate Optimization',
    description: 'Continuous conversion improvement with AI-generated test hypotheses, dynamic page elements, and personalized user journeys.',
    category: 'Analytics',
    mainCategory: 'Data & Analytics',
    icon: <FiTrendingUp />,
    color: 'linear-gradient(45deg, #F97316, #FDBA74)',
    features: [
      'Automated hypothesis generation from user behavior',
      'Dynamic page elements adapting to visitors',
      'Multivariate testing at scale across touchpoints',
      'Personalized user journeys based on prediction',
      'Continuous optimization without test cycles'
    ],
    link: '/services/conversion-optimization'
  },
  {
    id: 10,
    title: 'Influencer & Partnership Marketing',
    description: 'Data-driven influencer marketing with AI-powered creator discovery, performance prediction, and relationship management.',
    category: 'Social',
    mainCategory: 'Audience Engagement',
    icon: <FiStar />,
    color: 'linear-gradient(45deg, #A855F7, #C084FC)',
    features: [
      'AI-powered influencer discovery and matching',
      'Automated performance prediction and ROI forecasting',
      'Dynamic compensation modeling based on data',
      'Content compliance verification automation',
      'Fake follower and engagement detection'
    ],
    link: '/services/influencer-marketing'
  }
];

// Comparison data
const comparisons = [
  {
    aspect: 'Content Creation',
    icon: <FiLayers />,
    traditional: {
      summary: 'Manual creation by copywriters, slow iterations, limited output capacity',
      points: [
        'Labor-intensive process requiring specialized skills',
        'Limited by human production capacity',
        'Difficult to scale for multi-channel needs',
        'Slow iteration cycles for optimization'
      ]
    },
    ai: {
      summary: 'Automated generation with real-time optimization, unlimited scaling potential',
      points: [
        'AI-generated content at scale across formats',
        'Real-time optimization based on performance',
        'Instantaneous content adaptation for channels',
        'Continuous improvement without manual intervention'
      ]
    }
  },
  {
    aspect: 'Customer Targeting',
    icon: <FiUsers />,
    traditional: {
      summary: 'Basic demographic segments, manual updates, limited personalization',
      points: [
        'Broad demographic segments only',
        'Manual segment creation and maintenance',
        'Limited personalization capabilities', 
        'Slow adaptation to changing behaviors'
      ]
    },
    ai: {
      summary: 'Dynamic micro-segments, behavioral prediction, individual-level personalization',
      points: [
        'AI-driven micro-segmentation',
        'Predictive modeling of customer behavior',
        'Individual-level personalization at scale',
        'Real-time adaptation to behavioral changes'
      ]
    }
  },
  {
    aspect: 'Campaign Management',
    icon: <FiMail />,
    traditional: {
      summary: 'Manual setup, periodic optimization, human decision-making',
      points: [
        'Time-consuming manual campaign creation',
        'Periodic optimization requiring expertise',
        'Limited by availability of human resources',
        'Reactive rather than proactive adjustments'
      ]
    },
    ai: {
      summary: 'Automated setup, continuous optimization, data-driven decisions',
      points: [
        'Automated campaign generation and deployment',
        'Continuous real-time optimization',
        'AI-driven decision making without human delay',
        'Proactive adjustments based on predictive analytics'
      ]
    }
  },
  {
    aspect: 'Performance Analytics',
    icon: <FiPieChart />,
    traditional: {
      summary: 'Manual report creation, retrospective analysis, limited insights',
      points: [
        'Labor-intensive manual reporting processes',
        'Analysis limited to past performance only',
        'Basic data visualization without deep insights',
        'Difficult to connect data across channels'
      ]
    },
    ai: {
      summary: 'Automated reporting, predictive modeling, anomaly detection, natural language insights',
      points: [
        'Automated report generation with natural language',
        'Predictive forecasting of future performance',
        'Anomaly detection highlighting opportunities',
        'Cross-channel data integration and analysis'
      ]
    }
  },
  {
    aspect: 'Resource Requirements',
    icon: <FiDollarSign />,
    traditional: {
      summary: 'Large specialized teams, high operational costs, limited scalability',
      points: [
        'Requires large teams of specialists',
        'High operational and labor costs',
        'Limited ability to scale without proportional hiring',
        'Knowledge siloed within individual team members'
      ]
    },
    ai: {
      summary: 'Small strategic teams, reduced operational costs, unlimited scalability',
      points: [
        'Small focused team of strategic planners',
        'Significantly reduced operational costs',
        'Unlimited scalability without additional staffing',
        'Centralized knowledge management and accessibility'
      ]
    }
  }
];

// Filter categories with more descriptive labels
const categories = [
  { id: 'all', label: 'All Services' },
  { id: 'Content & SEO', label: 'Content & SEO' },
  { id: 'Audience Engagement', label: 'Audience Engagement' },
  { id: 'Data & Analytics', label: 'Data & Analytics' },
  { id: 'Paid Marketing', label: 'Paid Marketing' }
];

// Simplified animation variants that won't cause blank content
const fadeInUpVariant = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.4 } },
  exit: { opacity: 0, y: -20, transition: { duration: 0.3 } }
};

// Add these missing variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const tableRowVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96]
    }
  }
};

const Services = () => {
  const [activeFilter, setActiveFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const tableControls = useAnimation();
  const comparisonControls = useAnimation();
  
  // Create a ref for the services section to scroll to
  const servicesRef = useRef(null);
  
  // Animation on page load
  useEffect(() => {
    tableControls.start('visible');
    comparisonControls.start('visible');
  }, [tableControls, comparisonControls]);
  
  // Handle filter change without animation complexity
  const handleFilterChange = (filterId) => {
    setActiveFilter(filterId);
  };
  
  // Filter services based on active filter using mainCategory and search query
  const filteredServices = services.filter(service => 
    (activeFilter === 'all' || service.mainCategory === activeFilter) &&
    (searchQuery === '' || 
     service.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
     service.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );
  
  // Handle search submit - both for button click and Enter key
  const handleSearchSubmit = (e) => {
    if (e) e.preventDefault();
    // Scroll to services section with smooth behavior
    servicesRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  
  // Handle Enter key press in search input
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchSubmit(e);
    }
  };
  
  return (
    <>
      <HeaderSection>
        <HeaderContent>
          <Container>
            <AnimatedSection animation="fadeInUp">
              <HeaderSubtitle>AI-Powered Solutions</HeaderSubtitle>
              <HeaderTitle>
                Transform Your Marketing with <span>Intelligent Automation</span>
              </HeaderTitle>
              <HeaderDescription>
                Replace traditional marketing workflows with intelligent, automated pipelines that save time, reduce costs, and drive better results.
              </HeaderDescription>
              
              <SearchBar as="form" onSubmit={handleSearchSubmit}>
                <SearchInput 
                  type="text" 
                  placeholder="Search for a service..." 
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <SearchButton type="button" onClick={handleSearchSubmit}>
                  Find Solutions
                </SearchButton>
              </SearchBar>
            </AnimatedSection>
          </Container>
        </HeaderContent>
      </HeaderSection>
      
      <FilterSection>
        <Container>
          <AnimatedSection animation="fadeIn" delay={0.3}>
            <FilterContainer>
              {categories.map(category => (
                <FilterButton
                  key={category.id}
                  active={activeFilter === category.id}
                  onClick={() => handleFilterChange(category.id)}
                >
                  {category.label}
                </FilterButton>
              ))}
            </FilterContainer>
          </AnimatedSection>
        </Container>
      </FilterSection>
      
      <ServicesSection ref={servicesRef}>
        <Container>
          <ServicesGrid>
            {filteredServices.length > 0 ? (
              filteredServices.map((service) => (
                <motion.div 
                  key={service.id} 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  layout
                >
                  <ServiceCard>
                    <ServiceHeader color={service.color}>
                      <ServiceIconContainer color={service.color}>
                        {service.icon}
                      </ServiceIconContainer>
                      <ServiceInfo>
                        <ServiceTag category={service.category}>
                          {service.category}
                        </ServiceTag>
                        <ServiceTitle>{service.title}</ServiceTitle>
                      </ServiceInfo>
                    </ServiceHeader>
                    
                    <ServiceDescription>{service.description}</ServiceDescription>
                    
                    <ServiceFeatures>
                      {service.features.map((feature, featureIndex) => (
                        <ServiceFeature 
                          key={featureIndex} 
                          index={featureIndex}
                          color={service.color.split(',')[0].split('(')[1]}
                        >
                          {feature}
                        </ServiceFeature>
                      ))}
                    </ServiceFeatures>
                    
                    <ServiceLink to={service.link} color={service.color.split(',')[0].split('(')[1]}>
                      Learn more <FiArrowRight />
                    </ServiceLink>
                  </ServiceCard>
                </motion.div>
              ))
            ) : (
              <p>No services found for this category. Please try another filter.</p>
            )}
          </ServicesGrid>
        </Container>
      </ServicesSection>
      
      <ComparisonSection>
        <Container>
          <AnimatedSection animation="fadeInUp">
            <SectionHeader>
              <SectionTitle>Traditional vs. AI-Powered Marketing</SectionTitle>
              <SectionDescription>
                See how our AI-powered marketing pipelines compare to traditional marketing approaches.
              </SectionDescription>
            </SectionHeader>
          </AnimatedSection>
          
          <ComparisonWrapper>
            <motion.div
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: {
                    staggerChildren: 0.2
                  }
                }
              }}
              initial="hidden"
              animate={comparisonControls}
            >
              {comparisons.map((comparison, index) => (
                <motion.div
                  key={index}
                  variants={{
                    hidden: { opacity: 0, y: 30 },
                    visible: { 
                      opacity: 1, 
                      y: 0,
                      transition: { duration: 0.6, ease: "easeOut" }
                    }
                  }}
                >
                  <ComparisonItem>
                    <ComparisonHeader>
                      <AspectIcon>
                        {comparison.icon}
                      </AspectIcon>
                      <AspectTitle>{comparison.aspect}</AspectTitle>
                    </ComparisonHeader>
                    <ComparisonContent>
                      <ComparisonColumn>
                        <ColumnLabel>
                          <LabelIcon type="traditional">
                            <FiArrowRight />
                          </LabelIcon>
                          <LabelText type="traditional">Traditional Marketing</LabelText>
                        </ColumnLabel>
                        <ComparisonPoints>
                          {comparison.traditional.points.map((point, i) => (
                            <ComparisonPoint key={i} type="traditional">
                              {point}
                            </ComparisonPoint>
                          ))}
                        </ComparisonPoints>
                      </ComparisonColumn>
                      <ComparisonColumn>
                        <ColumnLabel>
                          <LabelIcon type="ai">
                            <FiArrowRight />
                          </LabelIcon>
                          <LabelText type="ai">BetterMarkt AI Solution</LabelText>
                        </ColumnLabel>
                        <ComparisonPoints>
                          {comparison.ai.points.map((point, i) => (
                            <ComparisonPoint key={i} type="ai">
                              {point}
                            </ComparisonPoint>
                          ))}
                        </ComparisonPoints>
                      </ComparisonColumn>
                    </ComparisonContent>
                  </ComparisonItem>
                </motion.div>
              ))}
            </motion.div>
          </ComparisonWrapper>
        </Container>
      </ComparisonSection>
      
      <CTASection>
        <AnimatedSection animation="scaleUp">
          <Container>
            <CTATitle>Ready to Transform Your Marketing?</CTATitle>
            <CTADescription>
              Replace your traditional marketing workflows with AI-powered pipelines. Get started today and see the difference in efficiency, costs, and results.
            </CTADescription>
            <CTAButtons>
              <CTAButton 
                size={Button.SIZES.LARGE} 
                as={Link} 
                to="/pricing"
              >
                View Pricing <FiArrowRight />
              </CTAButton>
              <CTAButton 
                $size="large" 
                as={Link} 
                to="/contact/sales"
                $variant="outline"
              >
                Contact Sales <FiHeadphones />
              </CTAButton>
            </CTAButtons>
          </Container>
        </AnimatedSection>
      </CTASection>
    </>
  );
};

export default Services; 