import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import theme from './styles/theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Pricing from './pages/Pricing';
import About from './pages/About';
import Contact from './pages/Contact';
import SalesContact from './pages/SalesContact';
import NotFound from './pages/NotFound';
import CaseStudies from './pages/CaseStudies';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Cookies from './pages/Cookies';
import Unsubscribe from './pages/Unsubscribe';
import Preloader from './components/Preloader';
import { PreloaderProvider, usePreloader } from './context/PreloaderContext';

// Main App content with preloader logic
const AppContent = () => {
  const { loading, completeLoading } = usePreloader();
  
  return (
    <>
      {loading && <Preloader onComplete={completeLoading} />}
      <Router>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/case-studies" element={<CaseStudies />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact/sales" element={<SalesContact />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/thank-you" element={<iframe src="/contact-thanks.html" style={{border: 'none', width: '100%', height: '100vh'}} title="Thank You" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <PreloaderProvider>
        <AppContent />
      </PreloaderProvider>
    </ThemeProvider>
  );
}

export default App;
