import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiTarget, FiAward, FiUsers, FiCheck, FiTrendingUp, FiLayers, FiBriefcase, FiGlobe } from 'react-icons/fi';
import Container from '../components/Container';
import Button from '../components/Button';

// Hero Section
const HeroSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[32]} 0 ${theme.spacing[16]}`};
  background: ${({ theme }) => theme.colors.gradients.primary};
  text-align: center;
  color: white;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => `${theme.spacing[24]} 0 ${theme.spacing[12]}`};
  }
`;

const HeroTitle = styled.h1`
  color: white;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const HeroDescription = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 0 auto;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.typography.fontSize.lg};
  }
`;

// Mission Section
const MissionSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const MissionContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing[12]};
  align-items: center;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing[8]};
  }
`;

const MissionContent = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    order: 2;
  }
`;

const SectionTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  
  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const SectionDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  font-size: ${({ theme }) => theme.typography.fontSize.lg};
`;

const MissionPoints = styled.div`
  margin-top: ${({ theme }) => theme.spacing[8]};
`;

const MissionPoint = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  align-items: flex-start;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const MissionIconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  background-color: ${({ theme }) => `${theme.colors.primary.main}15`};
  color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing[4]};
  font-size: 20px;
  flex-shrink: 0;
`;

const MissionPointContent = styled.div`
  flex: 1;
`;

const MissionPointTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const MissionPointDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const MissionImage = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  overflow: hidden;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    order: 1;
    height: 300px;
  }
  
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    
    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      height: 300px;
    }
  }
`;

// Values Section
const ValuesSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

const ValuesSectionHeader = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto ${({ theme }) => theme.spacing[12]};
`;

const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing[6]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const ValueCard = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  padding: ${({ theme }) => theme.spacing[6]};
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

const ValueIconContainer = styled.div`
  width: 56px;
  height: 56px;
  border-radius: ${({ theme }) => theme.borders.radius.full};
  background: ${({ color, theme }) => color || theme.colors.gradients.primary};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  font-size: 24px;
`;

const ValueTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const ValueDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

// Team Section
const TeamSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const TeamSectionHeader = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto ${({ theme }) => theme.spacing[12]};
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing[6]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const TeamMemberCard = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

const TeamMemberImage = styled.div`
  height: 280px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  }
  
  ${TeamMemberCard}:hover & img {
    transform: scale(1.05);
  }
`;

const TeamMemberInfo = styled.div`
  padding: ${({ theme }) => theme.spacing[5]};
`;

const TeamMemberName = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

const TeamMemberRole = styled.p`
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const TeamMemberBio = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
`;

// Timeline Section
const TimelineSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

const TimelineSectionHeader = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto ${({ theme }) => theme.spacing[12]};
`;

const Timeline = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.border.main};
    transform: translateX(-50%);
    
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      left: 30px;
    }
  }
`;

const TimelineItem = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: ${({ position }) => position === 'left' ? 'flex-end' : 'flex-start'};
  padding-bottom: ${({ theme }) => theme.spacing[12]};
  width: 100%;
  
  &:last-child {
    padding-bottom: 0;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-start;
    padding-left: 60px;
  }
`;

const TimelineContent = styled.div`
  width: calc(50% - 30px);
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  padding: ${({ theme }) => theme.spacing[5]};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
  }
`;

const TimelineDot = styled.div`
  position: absolute;
  left: 50%;
  top: 20px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary.main};
  transform: translateX(-50%);
  z-index: 1;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    left: 30px;
  }
`;

const TimelineDate = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.colors.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const TimelineTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const TimelineDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
`;

// CTA Section
const CTASection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background: ${({ theme }) => theme.colors.gradients.secondary};
  color: white;
  text-align: center;
`;

const CTATitle = styled.h2`
  color: white;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const CTADescription = styled.p`
  color: rgba(255, 255, 255, 0.9);
  font-size: ${({ theme }) => theme.typography.fontSize.lg};
  max-width: 800px;
  margin: 0 auto ${({ theme }) => theme.spacing[8]};
`;

const CTAButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[4]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
  }
`;

const CTAButton = styled(Button)`
  background-color: white;
  color: ${({ theme }) => theme.colors.primary.main};
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

// Data for the page
const missionPoints = [
  {
    id: 1,
    icon: <FiTarget />,
    title: 'Transforming Marketing Through AI',
    description: 'Our mission is to revolutionize digital marketing by replacing traditional, labor-intensive workflows with intelligent, AI-powered pipelines that deliver superior results.'
  },
  {
    id: 2,
    icon: <FiTrendingUp />,
    title: 'Democratizing Advanced Marketing',
    description: 'We believe that cutting-edge marketing technology should be accessible to businesses of all sizes, not just enterprises with massive budgets.'
  },
  {
    id: 3,
    icon: <FiUsers />,
    title: 'Empowering Marketers',
    description: 'We aim to free marketers from repetitive tasks so they can focus on strategy, creativity, and building authentic connections with their audience.'
  }
];

const values = [
  {
    id: 1,
    icon: <FiLayers />,
    title: 'Innovation',
    description: 'We constantly push the boundaries of what\'s possible with AI and marketing technology.',
    color: 'linear-gradient(45deg, #0EA5E9, #38BDF8)'
  },
  {
    id: 2,
    icon: <FiUsers />,
    title: 'Customer Success',
    description: 'Our clients\' success is our success. We measure our worth by the results we create for them.',
    color: 'linear-gradient(45deg, #F97316, #FDBA74)'
  },
  {
    id: 3,
    icon: <FiCheck />,
    title: 'Quality',
    description: 'We believe in delivering solutions that exceed expectations in effectiveness, reliability, and usability.',
    color: 'linear-gradient(45deg, #10B981, #34D399)'
  },
  {
    id: 4,
    icon: <FiAward />,
    title: 'Excellence',
    description: 'We strive for excellence in everything we do, from code to customer service.',
    color: 'linear-gradient(45deg, #0EA5E9, #38BDF8)'
  },
  {
    id: 5,
    icon: <FiBriefcase />,
    title: 'Integrity',
    description: 'We operate with honesty, transparency, and ethical practices in all our business dealings.',
    color: 'linear-gradient(45deg, #F97316, #FDBA74)'
  },
  {
    id: 6,
    icon: <FiGlobe />,
    title: 'Impact',
    description: "We're committed to creating technology that makes a meaningful difference for businesses and their customers.",
    color: 'linear-gradient(45deg, #10B981, #34D399)'
  }
];

const teamMembers = [
  {
    id: 1,
    name: 'Alex Chen',
    role: 'CEO & Founder',
    bio: 'Former VP of Marketing at AdTech Inc. with 15+ years of experience in digital marketing and AI implementation. Passionate about transforming how businesses approach marketing.',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
  },
  {
    id: 2,
    name: 'Sophia Rodriguez',
    role: 'CTO',
    bio: 'AI researcher and engineer with a Ph.D. in Machine Learning. Led AI teams at major tech companies before joining BetterMarkt to build our proprietary AI marketing technology.',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
  },
  {
    id: 3,
    name: 'David Kim',
    role: 'VP of Product',
    bio: 'Product strategist with experience at leading SaaS companies. Specializes in creating intuitive user experiences for complex technological solutions.',
    image: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
  },
  {
    id: 4,
    name: 'Emily Johnson',
    role: 'VP of Marketing',
    bio: 'Digital marketing expert who has led campaigns for Fortune 500 companies. Now uses her expertise to help shape BetterMarkt\'s marketing strategy and product roadmap.',
    image: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=776&q=80'
  },
  {
    id: 5,
    name: 'Michael Taylor',
    role: 'VP of Sales',
    bio: 'Experienced sales leader in the MarTech space. Built and scaled sales teams at multiple successful startups before joining BetterMarkt.',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'
  },
  {
    id: 6,
    name: 'Sarah Wright',
    role: 'VP of Customer Success',
    bio: 'Customer success strategist focused on helping clients achieve measurable ROI. Previously led customer success at a leading AI company.',
    image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'
  }
];

const timelineEvents = [
  {
    id: 1,
    date: 'January 2022',
    title: 'BetterMarkt Founded',
    description: 'Alex Chen founded BetterMarkt with a vision to revolutionize digital marketing through AI automation.',
    position: 'right'
  },
  {
    id: 2,
    date: 'April 2022',
    title: 'Seed Funding Round',
    description: 'Secured $3.5M in seed funding from leading venture capital firms specializing in AI and MarTech.',
    position: 'left'
  },
  {
    id: 3,
    date: 'August 2022',
    title: 'Core Team Assembled',
    description: 'Built the founding team of AI engineers, marketing experts, and product designers.',
    position: 'right'
  },
  {
    id: 4,
    date: 'November 2022',
    title: 'Alpha Release',
    description: 'Launched alpha version of the platform with the first three AI marketing pipelines.',
    position: 'left'
  },
  {
    id: 5,
    date: 'March 2023',
    title: 'Beta Program',
    description: 'Enrolled 20 companies in our closed beta program, refining our technology based on real-world usage.',
    position: 'right'
  },
  {
    id: 6,
    date: 'September 2023',
    title: 'Official Launch',
    description: 'Publicly launched BetterMarkt with 10 fully operational AI marketing pipelines.',
    position: 'left'
  },
  {
    id: 7,
    date: 'January 2024',
    title: 'Series A Funding',
    description: 'Secured $12M in Series A funding to accelerate growth and expand our AI marketing capabilities.',
    position: 'right'
  }
];

const About = () => {
  return (
    <>
      <HeroSection>
        <Container>
          <HeroTitle>Our Story</HeroTitle>
          <HeroDescription>
            Meet the team behind BetterMarkt and learn why we're passionate about revolutionizing digital marketing with artificial intelligence.
          </HeroDescription>
        </Container>
      </HeroSection>
      
      <MissionSection>
        <MissionContainer>
          <MissionContent>
            <SectionTitle>Our <span>Mission</span></SectionTitle>
            <SectionDescription>
              We're on a mission to transform how marketing works by replacing traditional workflows with intelligent, AI-powered pipelines that deliver superior results with less effort.
            </SectionDescription>
            
            <MissionPoints>
              {missionPoints.map(point => (
                <MissionPoint key={point.id}>
                  <MissionIconContainer>
                    {point.icon}
                  </MissionIconContainer>
                  <MissionPointContent>
                    <MissionPointTitle>{point.title}</MissionPointTitle>
                    <MissionPointDescription>{point.description}</MissionPointDescription>
                  </MissionPointContent>
                </MissionPoint>
              ))}
            </MissionPoints>
          </MissionContent>
          
          <MissionImage>
            <img 
              src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" 
              alt="Team collaboration"
            />
          </MissionImage>
        </MissionContainer>
      </MissionSection>
      
      <ValuesSection>
        <Container>
          <ValuesSectionHeader>
            <SectionTitle>Our <span>Values</span></SectionTitle>
            <SectionDescription>
              These core principles guide everything we do, from product development to customer interactions.
            </SectionDescription>
          </ValuesSectionHeader>
          
          <ValuesGrid>
            {values.map((value, index) => (
              <ValueCard
                key={value.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <ValueIconContainer color={value.color}>
                  {value.icon}
                </ValueIconContainer>
                <ValueTitle>{value.title}</ValueTitle>
                <ValueDescription>{value.description}</ValueDescription>
              </ValueCard>
            ))}
          </ValuesGrid>
        </Container>
      </ValuesSection>
      
      <TeamSection>
        <Container>
          <TeamSectionHeader>
            <SectionTitle>Our <span>Team</span></SectionTitle>
            <SectionDescription>
              Meet the passionate experts behind BetterMarkt who are dedicated to transforming marketing through AI.
            </SectionDescription>
          </TeamSectionHeader>
          
          <TeamGrid>
            {teamMembers.map((member, index) => (
              <TeamMemberCard
                key={member.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <TeamMemberImage>
                  <img src={member.image} alt={member.name} />
                </TeamMemberImage>
                <TeamMemberInfo>
                  <TeamMemberName>{member.name}</TeamMemberName>
                  <TeamMemberRole>{member.role}</TeamMemberRole>
                  <TeamMemberBio>{member.bio}</TeamMemberBio>
                </TeamMemberInfo>
              </TeamMemberCard>
            ))}
          </TeamGrid>
        </Container>
      </TeamSection>
      
      <TimelineSection>
        <Container>
          <TimelineSectionHeader>
            <SectionTitle>Our <span>Journey</span></SectionTitle>
            <SectionDescription>
              The key milestones that have shaped BetterMarkt since our founding.
            </SectionDescription>
          </TimelineSectionHeader>
          
          <Timeline>
            {timelineEvents.map((event, index) => (
              <TimelineItem 
                key={event.id} 
                position={event.position}
                initial={{ opacity: 0, x: event.position === 'left' ? -50 : 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <TimelineContent>
                  <TimelineDate>{event.date}</TimelineDate>
                  <TimelineTitle>{event.title}</TimelineTitle>
                  <TimelineDescription>{event.description}</TimelineDescription>
                </TimelineContent>
                <TimelineDot />
              </TimelineItem>
            ))}
          </Timeline>
        </Container>
      </TimelineSection>
      
      <CTASection>
        <Container>
          <CTATitle>Join Us on Our Mission</CTATitle>
          <CTADescription>
            Ready to experience the future of marketing? Let's transform your marketing workflows into efficient, AI-powered pipelines.
          </CTADescription>
          <CTAButtons>
            <CTAButton 
              $size="large" 
              as={Link} 
              to="/contact"
            >
              Get in Touch
            </CTAButton>
            <CTAButton 
              $size="large" 
              as={Link} 
              to="/careers"
              $variant="outline"
            >
              Join Our Team
            </CTAButton>
          </CTAButtons>
        </Container>
      </CTASection>
    </>
  );
};

export default About; 