import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

// Use $-prefixed props to prevent them from being passed to the DOM
const IllustrationContainer = styled(motion.div)`
  width: ${props => props.$width || '100%'};
  height: ${props => props.$height || 'auto'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.$borderRadius || '0'};
  overflow: hidden;
  background-color: ${props => props.$backgroundColor || 'transparent'};
  box-shadow: ${props => props.$shadow ? '0 20px 40px rgba(0, 0, 0, 0.05)' : 'none'};
  position: relative;
`;

// Wrapper for the DotLottieReact component to ensure proper sizing
const LottieWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

// Simple SVG placeholder to show when Lottie fails to load
const PlaceholderSVG = styled.svg`
  width: 100%;
  height: 100%;
  min-height: 200px;
`;

// Fallback component to show when Lottie fails to load
const FallbackContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  background-color: ${props => props.$backgroundColor || '#f8f9fc'};
  color: #666;
`;

const FallbackIcon = styled.div`
  font-size: 48px;
  margin-bottom: 12px;
  opacity: 0.6;
`;

const FallbackText = styled.div`
  font-size: 14px;
  opacity: 0.7;
`;

// Fallback static SVG animations
const FALLBACK_SVGS = {
  marketingDashboard: (
    <PlaceholderSVG viewBox="0 0 200 200">
      <rect x="20" y="20" width="160" height="40" rx="5" fill="#e6e9f0" />
      <rect x="20" y="70" width="70" height="110" rx="5" fill="#e6e9f0" />
      <rect x="100" y="70" width="80" height="50" rx="5" fill="#e6e9f0" />
      <rect x="100" y="130" width="80" height="50" rx="5" fill="#e6e9f0" />
    </PlaceholderSVG>
  ),
  analytics: (
    <PlaceholderSVG viewBox="0 0 200 200">
      <rect x="20" y="140" width="30" height="40" rx="2" fill="#e6e9f0" />
      <rect x="60" y="110" width="30" height="70" rx="2" fill="#e6e9f0" />
      <rect x="100" y="80" width="30" height="100" rx="2" fill="#e6e9f0" />
      <rect x="140" y="40" width="30" height="140" rx="2" fill="#e6e9f0" />
      <path d="M30 80 L170 40" stroke="#e6e9f0" strokeWidth="2" />
    </PlaceholderSVG>
  ),
  implementation: (
    <PlaceholderSVG viewBox="0 0 200 200">
      <rect x="40" y="40" width="120" height="120" rx="10" fill="#e6e9f0" />
      <rect x="60" y="60" width="80" height="10" rx="2" fill="#d0d4df" />
      <rect x="60" y="80" width="80" height="10" rx="2" fill="#d0d4df" />
      <rect x="60" y="100" width="40" height="10" rx="2" fill="#d0d4df" />
      <circle cx="100" cy="130" r="15" fill="#d0d4df" />
    </PlaceholderSVG>
  ),
  optimization: (
    <PlaceholderSVG viewBox="0 0 200 200">
      <circle cx="100" cy="100" r="60" fill="#e6e9f0" />
      <path d="M100 50 L100 100 L140 100" stroke="#d0d4df" strokeWidth="4" strokeLinecap="round" />
      <circle cx="100" cy="100" r="5" fill="#d0d4df" />
    </PlaceholderSVG>
  )
};

// CDN URLs for animations - using the new dotlottie format
// Fallback to normal JSON files if dotlottie causes issues
const CDN_URLS = {
  // Marketing dashboard animation
  marketingDashboard: 'https://lottie.host/fd65d21c-34a2-4872-8422-e4d57fb278b0/R4XTnrVyR1.lottie',
  // Data analysis animation
  analytics: 'https://lottie.host/1bb2629a-9fe1-442e-873e-e4baebfa1803/hWXZVt4Hg9.lottie',
  // Implementation animation
  implementation: 'https://lottie.host/df700230-ce4b-4bc6-a159-b21b22661428/TkzqUTzpdO.lottie',
  // Optimization animation
  optimization: 'https://lottie.host/57206b5f-d558-4fbd-a781-82b0572c306a/a4Zz3VYFvH.lottie'
};

// LottieComponent with error handling
const LottieComponent = ({ src, loop, autoplay, type }) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    // Reset error state when src changes
    setHasError(false);
    setIsLoading(true);
    
    // Set loading to false after a short delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    
    return () => clearTimeout(timer);
  }, [src]);

  if (hasError || isLoading) {
    return FALLBACK_SVGS[type] || (
      <FallbackContainer>
        <FallbackIcon>📊</FallbackIcon>
        <FallbackText>Visualization</FallbackText>
      </FallbackContainer>
    );
  }

  try {
    return (
      <DotLottieReact
        src={src}
        loop={loop}
        autoplay={autoplay}
        onError={() => setHasError(true)}
        style={{ width: '100%', height: '100%' }}
        options={{
          renderer: 'svg', // Explicitly use SVG renderer to avoid canvas/ImageData issues
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: true,
            hideOnTransparent: true
          }
        }}
      />
    );
  } catch (error) {
    console.error('Error rendering Lottie animation:', error);
    return FALLBACK_SVGS[type] || (
      <FallbackContainer>
        <FallbackIcon>📈</FallbackIcon>
        <FallbackText>Analytics Visualization</FallbackText>
      </FallbackContainer>
    );
  }
};

// Marketing Dashboard Illustration
const MarketingDashboard = ({ 
  width = '100%', 
  height = 'auto', 
  className, 
  borderRadius = '8px',
  loop = true,
  autoplay = true,
  shadow = false,
  backgroundColor = 'transparent'
}) => {
  return (
    <IllustrationContainer
      $width={width}
      $height={height}
      className={className}
      $borderRadius={borderRadius}
      $shadow={shadow}
      $backgroundColor={backgroundColor}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <LottieWrapper>
        <LottieComponent
          src={CDN_URLS.marketingDashboard}
          loop={loop}
          autoplay={autoplay}
          type="marketingDashboard"
        />
      </LottieWrapper>
    </IllustrationContainer>
  );
};

// Analytics Illustration
const AnalyticsIllustration = ({ 
  width = '100%', 
  height = 'auto', 
  className, 
  borderRadius = '8px',
  loop = true,
  autoplay = true,
  shadow = false,
  backgroundColor = 'transparent'
}) => {
  return (
    <IllustrationContainer
      $width={width}
      $height={height}
      className={className}
      $borderRadius={borderRadius}
      $shadow={shadow}
      $backgroundColor={backgroundColor}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <LottieWrapper>
        <LottieComponent
          src={CDN_URLS.analytics}
          loop={loop}
          autoplay={autoplay}
          type="analytics"
        />
      </LottieWrapper>
    </IllustrationContainer>
  );
};

// Implementation Illustration
const ImplementationIllustration = ({ 
  width = '100%', 
  height = 'auto', 
  className, 
  borderRadius = '8px',
  loop = true,
  autoplay = true,
  shadow = false,
  backgroundColor = 'transparent'
}) => {
  return (
    <IllustrationContainer
      $width={width}
      $height={height}
      className={className}
      $borderRadius={borderRadius}
      $shadow={shadow}
      $backgroundColor={backgroundColor}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <LottieWrapper>
        <LottieComponent
          src={CDN_URLS.implementation}
          loop={loop}
          autoplay={autoplay}
          type="implementation"
        />
      </LottieWrapper>
    </IllustrationContainer>
  );
};

// Optimization Illustration
const OptimizationIllustration = ({ 
  width = '100%', 
  height = 'auto', 
  className, 
  borderRadius = '8px',
  loop = true,
  autoplay = true,
  shadow = false,
  backgroundColor = 'transparent'
}) => {
  return (
    <IllustrationContainer
      $width={width}
      $height={height}
      className={className}
      $borderRadius={borderRadius}
      $shadow={shadow}
      $backgroundColor={backgroundColor}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <LottieWrapper>
        <LottieComponent
          src={CDN_URLS.optimization}
          loop={loop}
          autoplay={autoplay}
          type="optimization"
        />
      </LottieWrapper>
    </IllustrationContainer>
  );
};

// Export all illustrations as a single object
const VectorIllustration = {
  MarketingDashboard,
  AnalyticsIllustration,
  ImplementationIllustration,
  OptimizationIllustration
};

export default VectorIllustration; 