import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { Helmet } from 'react-helmet-async';
import Container from '../components/Container';
import Button from '../components/Button';

const NotFoundSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[32]} 0`};
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => `${theme.spacing[24]} 0`};
  }
`;

const NotFoundContent = styled(motion.div)`
  max-width: 600px;
`;

const ErrorCode = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSize['6xl']};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.colors.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  line-height: 1;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.typography.fontSize['5xl']};
  }
`;

const Title = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.lg};
  margin-bottom: ${({ theme }) => theme.spacing[10]};
`;

const HomeButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  
  svg {
    margin-right: ${({ theme }) => theme.spacing[2]};
  }
`;

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 Page Not Found | BetterMarkt</title>
        <meta name="description" content="The page you are looking for cannot be found. Navigate back to BetterMarkt's homepage for AI-powered digital marketing solutions." />
        <meta name="robots" content="noindex, follow" />
        <link rel="canonical" href="https://bettermarkt.com/404" />
      </Helmet>
      <NotFoundSection>
        <Container>
          <NotFoundContent
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ErrorCode>404</ErrorCode>
            <Title>Page Not Found</Title>
            <Description>
              The page you are looking for might have been removed, had its name changed, 
              or is temporarily unavailable.
            </Description>
            <HomeButton as={Link} to="/" size={Button.SIZES.LARGE}>
              <FiArrowLeft />
              Return to Homepage
            </HomeButton>
          </NotFoundContent>
        </Container>
      </NotFoundSection>
    </>
  );
};

export default NotFound; 