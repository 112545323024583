import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiMapPin, FiMail, FiSend, FiInfo, FiAlertCircle, FiCheckCircle, FiArrowRight } from 'react-icons/fi';
import Container from '../components/Container';
import Button from '../components/Button';

// Hero Section
const HeroSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[32]} 0 ${theme.spacing[16]}`};
  background: ${({ theme }) => theme.colors.gradients.primary};
  text-align: center;
  color: white;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => `${theme.spacing[24]} 0 ${theme.spacing[12]}`};
  }
`;

const HeroTitle = styled.h1`
  color: white;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const HeroDescription = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 0 auto;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.typography.fontSize.lg};
  }
`;

// Contact Section
const ContactSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing[10]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing[10]};
  }
`;

const ContactInfo = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    order: 2;
  }
`;

const InfoTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  
  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const InfoDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  font-size: ${({ theme }) => theme.typography.fontSize.lg};
`;

const ContactInfoList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

const ContactInfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const ContactIconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  background-color: ${({ theme }) => `${theme.colors.primary.main}15`};
  color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing[4]};
  font-size: 20px;
  flex-shrink: 0;
`;

const ContactInfoContent = styled.div`
  flex: 1;
`;

const ContactInfoLabel = styled.p`
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

const ContactInfoText = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const SalesSection = styled.div`
  padding: ${({ theme }) => theme.spacing[6]};
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  margin-top: ${({ theme }) => theme.spacing[8]};
`;

const SalesTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const SalesDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const SalesLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  color: ${({ theme }) => theme.colors.primary.main};
  
  svg {
    margin-left: ${({ theme }) => theme.spacing[1]};
  }
`;

// Contact Form
const ContactForm = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  padding: ${({ theme }) => theme.spacing[8]};
  box-shadow: ${({ theme }) => theme.shadows.md};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    order: 1;
  }
`;

const FormTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  
  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[5]};
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing[4]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing[5]};
  }
`;

const FormLabel = styled.label`
  display: block;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const FormInput = styled.input`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[4]}`};
  border: 1px solid ${({ theme, error }) => 
    error ? theme.colors.system.error : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ theme }) => theme.colors.background.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.base};
  transition: border-color ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  
  &:focus {
    outline: none;
    border-color: ${({ theme, error }) => 
      error ? theme.colors.system.error : theme.colors.primary.main};
  }
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[4]}`};
  border: 1px solid ${({ theme, error }) => 
    error ? theme.colors.system.error : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ theme }) => theme.colors.background.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.base};
  min-height: 150px;
  resize: vertical;
  transition: border-color ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  
  &:focus {
    outline: none;
    border-color: ${({ theme, error }) => 
      error ? theme.colors.system.error : theme.colors.primary.main};
  }
`;

const FormSelect = styled.select`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[4]}`};
  border: 1px solid ${({ theme, error }) => 
    error ? theme.colors.system.error : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ theme }) => theme.colors.background.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.base};
  transition: border-color ${({ theme }) => theme.transitions.duration.normal} ${({ theme }) => theme.transitions.timing.ease};
  
  &:focus {
    outline: none;
    border-color: ${({ theme, error }) => 
      error ? theme.colors.system.error : theme.colors.primary.main};
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.system.error};
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
  margin-top: ${({ theme }) => theme.spacing[1]};
  display: flex;
  align-items: center;
  
  svg {
    margin-right: ${({ theme }) => theme.spacing[1]};
  }
`;

const SubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing[4]};
  
  svg {
    margin-right: ${({ theme }) => theme.spacing[2]};
  }
`;

const FormMessage = styled.div`
  margin-top: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => theme.spacing[4]};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  display: flex;
  align-items: flex-start;
  
  background-color: ${({ theme, type }) => {
    switch (type) {
      case 'success':
        return `${theme.colors.secondary.main}15`;
      case 'error':
        return `${theme.colors.system.error}15`;
      default:
        return `${theme.colors.primary.main}15`;
    }
  }};
  
  color: ${({ theme, type }) => {
    switch (type) {
      case 'success':
        return theme.colors.secondary.main;
      case 'error':
        return theme.colors.system.error;
      default:
        return theme.colors.primary.main;
    }
  }};
  
  svg {
    margin-right: ${({ theme }) => theme.spacing[3]};
    flex-shrink: 0;
    font-size: 20px;
  }
`;

// FAQ Section
const FAQSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

const FAQHeader = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto ${({ theme }) => theme.spacing[12]};
`;

const FAQTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  
  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const FAQDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.lg};
`;

const FAQGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing[6]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const FAQItem = styled.div`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  padding: ${({ theme }) => theme.spacing[6]};
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

const FAQItemTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const FAQItemContent = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

// Office Section
const OfficeSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const OfficeHeader = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto ${({ theme }) => theme.spacing[10]};
`;

const OfficeTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  
  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const OfficeDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.lg};
`;

const OfficeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing[6]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const OfficeCard = styled.div`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

const OfficeImage = styled.div`
  height: 200px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const OfficeInfo = styled.div`
  padding: ${({ theme }) => theme.spacing[6]};
`;

const OfficeName = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const OfficeAddress = styled.address`
  font-style: normal;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const OfficeContact = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  
  &:last-child {
    margin-bottom: 0;
  }
  
  svg {
    margin-right: ${({ theme }) => theme.spacing[2]};
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

// Contact data
const contactInfo = [
  {
    id: 1,
    icon: <FiMail />,
    label: 'Email Us',
    text: 'contact@bettermarkt.com',
    link: 'mailto:contact@bettermarkt.com'
  },
  {
    id: 2,
    icon: <FiMapPin />,
    label: 'Headquarters',
    text: 'Dubai, UAE',
    link: null
  }
];

const officeLocations = [
  {
    id: 1,
    name: 'Dubai (HQ)',
    address: 'Dubai, UAE',
    email: 'contact@bettermarkt.com',
    image: 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80'
  },
  {
    id: 2,
    name: 'Bangalore',
    address: 'Bangalore, India',
    email: 'contact@bettermarkt.com',
    image: 'https://images.unsplash.com/photo-1587474260584-136574528ed5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80'
  },
  {
    id: 3,
    name: 'Berlin',
    address: 'Berlin, Germany',
    email: 'contact@bettermarkt.com',
    image: 'https://images.unsplash.com/photo-1560969184-10fe8719e047?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80'
  }
];

const faqItems = [
  {
    id: 1,
    question: 'How quickly can I get started with BetterMarkt?',
    answer: 'You can get started with BetterMarkt in as little as 48 hours. After signing up, our team will help you set up your account, integrate with your existing systems, and train your team on using our AI marketing pipelines.'
  },
  {
    id: 2,
    question: 'Do I need technical expertise to use BetterMarkt?',
    answer: 'No technical expertise is required to use BetterMarkt. Our platform is designed to be user-friendly for marketers, with intuitive interfaces and clear workflows. We also provide comprehensive training and support.'
  },
  {
    id: 3,
    question: 'Can BetterMarkt integrate with my existing marketing tools?',
    answer: 'Yes, BetterMarkt integrates with most popular marketing tools and platforms including CRMs, email marketing systems, social media platforms, analytics tools, and content management systems.'
  },
  {
    id: 4,
    question: 'Is there a minimum contract length?',
    answer: 'While we offer monthly plans, our competitive pricing is based on annual commitments. This allows us to provide you with the best value and ensures we can invest in your long-term success.'
  },
  {
    id: 5,
    question: 'How do you handle data privacy and security?',
    answer: "BetterMarkt takes data privacy and security seriously. We're GDPR and CCPA compliant, use encryption for all data, and have regular security audits. Your marketing data is never used to train our AI systems without explicit permission."
  },
  {
    id: 6,
    question: 'What kind of support is included?',
    answer: 'All plans include email and chat support with our customer success team. Our Enterprise plans also include dedicated account management, priority support, and regular strategy sessions.'
  }
];

const Contact = () => {
  // Form state
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    country: '',
    interest: '',
    message: ''
  });
  
  // Validation state
  const [errors, setErrors] = useState({});
  
  // Submission states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState(null);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when field is changed
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };
  
  const validateForm = () => {
    const newErrors = {};
    
    if (!formState.name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!formState.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
      newErrors.email = 'Email is invalid';
    }
    
    if (!formState.company.trim()) {
      newErrors.company = 'Company is required';
    }
    
    if (!formState.country.trim()) {
      newErrors.country = 'Country is required';
    }
    
    if (!formState.interest) {
      newErrors.interest = 'Please select your interest';
    }
    
    if (!formState.message.trim()) {
      newErrors.message = 'Message is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      setIsSubmitting(true);
      
      // Prepare form data to send to webhook
      const formData = {
        name: formState.name,
        phone: formState.phone,
        email: formState.email,
        interestedWorkflow: formState.interest,
        message: formState.message,
        country: formState.country,
        submittedAt: new Date().toISOString()
      };
      
      // Send data to webhook
      fetch('https://n8n.bettermarkt.com/webhook/bbf42bf0-61e0-4ebe-b767-5e38fba40d13', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Redirect to thank you page instead of showing in-page message
        window.location.href = '/contact-thanks.html';
      })
      .catch(error => {
        setIsSubmitting(false);
        setSubmitResult({
          type: 'error',
          message: 'There was a problem submitting your form. Please try again.'
        });
        console.error('Error:', error);
      });
    }
  };
  
  return (
    <>
      <HeroSection>
        <Container>
          <HeroTitle>Get in Touch</HeroTitle>
          <HeroDescription>
            Have questions about our AI marketing solutions? We're here to help. Reach out to our team today.
          </HeroDescription>
        </Container>
      </HeroSection>
      
      <ContactSection>
        <Container>
          <ContactGrid>
            <ContactInfo>
              <InfoTitle>Let's <span>Connect</span></InfoTitle>
              <InfoDescription>
                We'd love to hear from you! Whether you have a question about our platform, pricing, or anything else, our team is ready to answer all your questions.
              </InfoDescription>
              
              <ContactInfoList>
                {contactInfo.map(item => (
                  <ContactInfoItem key={item.id}>
                    <ContactIconContainer>
                      {item.icon}
                    </ContactIconContainer>
                    <ContactInfoContent>
                      <ContactInfoLabel>{item.label}</ContactInfoLabel>
                      {item.link ? (
                        <a href={item.link}>
                          <ContactInfoText>{item.text}</ContactInfoText>
                        </a>
                      ) : (
                        <ContactInfoText>{item.text}</ContactInfoText>
                      )}
                    </ContactInfoContent>
                  </ContactInfoItem>
                ))}
              </ContactInfoList>
              
              <SalesSection>
                <SalesTitle>Looking for a demo?</SalesTitle>
                <SalesDescription>
                  If you'd like to see BetterMarkt in action, our sales team would be happy to walk you through our platform and answer any questions.
                </SalesDescription>
                <SalesLink to="/contact/sales">
                  Schedule a demo <FiArrowRight />
                </SalesLink>
              </SalesSection>
            </ContactInfo>
            
            <ContactForm
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <FormTitle>Send Us a <span>Message</span></FormTitle>
              
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormGroup>
                    <FormLabel htmlFor="name">Full Name</FormLabel>
                    <FormInput
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Your name"
                      value={formState.name}
                      onChange={handleChange}
                      error={errors.name}
                    />
                    {errors.name && (
                      <ErrorMessage>
                        <FiAlertCircle />
                        {errors.name}
                      </ErrorMessage>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="email">Email Address</FormLabel>
                    <FormInput
                      id="email"
                      name="email"
                      type="email"
                      placeholder="your.email@company.com"
                      value={formState.email}
                      onChange={handleChange}
                      error={errors.email}
                    />
                    {errors.email && (
                      <ErrorMessage>
                        <FiAlertCircle />
                        {errors.email}
                      </ErrorMessage>
                    )}
                  </FormGroup>
                </FormRow>
                
                <FormRow>
                  <FormGroup>
                    <FormLabel htmlFor="phone">Phone Number</FormLabel>
                    <FormInput
                      id="phone"
                      name="phone"
                      type="tel"
                      placeholder="Your phone number"
                      value={formState.phone}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="company">Company</FormLabel>
                    <FormInput
                      id="company"
                      name="company"
                      type="text"
                      placeholder="Your company name"
                      value={formState.company}
                      onChange={handleChange}
                      error={errors.company}
                    />
                    {errors.company && (
                      <ErrorMessage>
                        <FiAlertCircle />
                        {errors.company}
                      </ErrorMessage>
                    )}
                  </FormGroup>
                </FormRow>
                
                <FormRow>
                  <FormGroup>
                    <FormLabel htmlFor="country">Country</FormLabel>
                    <FormInput
                      id="country"
                      name="country"
                      type="text"
                      placeholder="Your country"
                      value={formState.country}
                      onChange={handleChange}
                      error={errors.country}
                    />
                    {errors.country && (
                      <ErrorMessage>
                        <FiAlertCircle />
                        {errors.country}
                      </ErrorMessage>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="interest">I'm interested in</FormLabel>
                    <FormSelect
                      id="interest"
                      name="interest"
                      value={formState.interest}
                      onChange={handleChange}
                      error={errors.interest}
                    >
                      <option value="">Select your interest</option>
                      <option value="Content Creation">Content Creation & Management</option>
                      <option value="Personalization">Customer Segmentation & Personalization</option>
                      <option value="Social Media">Social Media Management</option>
                      <option value="Email Marketing">Email Marketing Automation</option>
                      <option value="SEO">SEO & Content Optimization</option>
                      <option value="Paid Advertising">PPC & Paid Advertising</option>
                      <option value="Analytics">Analytics & Reporting</option>
                      <option value="Other">Other</option>
                    </FormSelect>
                    {errors.interest && (
                      <ErrorMessage>
                        <FiAlertCircle />
                        {errors.interest}
                      </ErrorMessage>
                    )}
                  </FormGroup>
                </FormRow>
                
                <FormGroup>
                  <FormLabel htmlFor="message">Message</FormLabel>
                  <FormTextarea
                    id="message"
                    name="message"
                    placeholder="Tell us about your needs and how we can help..."
                    value={formState.message}
                    onChange={handleChange}
                    error={errors.message}
                  />
                  {errors.message && (
                    <ErrorMessage>
                      <FiAlertCircle />
                      {errors.message}
                    </ErrorMessage>
                  )}
                </FormGroup>
                
                <SubmitButton
                  type="submit"
                  size={Button.SIZES.LARGE}
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>Please wait...</>
                  ) : (
                    <>
                      <FiSend />
                      Send Message
                    </>
                  )}
                </SubmitButton>
              </form>
              
              {submitResult && (
                <FormMessage type={submitResult.type}>
                  {submitResult.type === 'success' ? (
                    <FiCheckCircle />
                  ) : (
                    <FiInfo />
                  )}
                  {submitResult.message}
                </FormMessage>
              )}
            </ContactForm>
          </ContactGrid>
        </Container>
      </ContactSection>
      
      <FAQSection>
        <Container>
          <FAQHeader>
            <FAQTitle>Frequently <span>Asked Questions</span></FAQTitle>
            <FAQDescription>
              Find quick answers to common questions about BetterMarkt's AI marketing solutions.
            </FAQDescription>
          </FAQHeader>
          
          <FAQGrid>
            {faqItems.map(item => (
              <FAQItem key={item.id}>
                <FAQItemTitle>{item.question}</FAQItemTitle>
                <FAQItemContent>{item.answer}</FAQItemContent>
              </FAQItem>
            ))}
          </FAQGrid>
        </Container>
      </FAQSection>
      
      <OfficeSection>
        <Container>
          <OfficeHeader>
            <OfficeTitle>Our <span>Offices</span></OfficeTitle>
            <OfficeDescription>
              BetterMarkt has offices in major tech hubs across the United States. Feel free to stop by!
            </OfficeDescription>
          </OfficeHeader>
          
          <OfficeGrid>
            {officeLocations.map(office => (
              <OfficeCard key={office.id}>
                <OfficeImage>
                  <img src={office.image} alt={office.name} />
                </OfficeImage>
                <OfficeInfo>
                  <OfficeName>{office.name}</OfficeName>
                  <OfficeAddress>
                    {office.address.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </OfficeAddress>
                  
                  <OfficeContact>
                    <FiMail />
                    <a href={`mailto:${office.email}`}>{office.email}</a>
                  </OfficeContact>
                </OfficeInfo>
              </OfficeCard>
            ))}
          </OfficeGrid>
        </Container>
      </OfficeSection>
    </>
  );
};

export default Contact; 