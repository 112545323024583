import React, { useRef } from 'react';
import styled from 'styled-components';
import { motion, useTransform, useScroll } from 'framer-motion';

const ParallaxContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${props => props.$height || 'auto'};
`;

const ParallaxItem = styled(motion.div)`
  position: ${props => props.$isAbsolute ? 'absolute' : 'relative'};
  top: ${props => props.$top || '0'};
  left: ${props => props.$left || '0'};
  right: ${props => props.$right || 'auto'};
  bottom: ${props => props.$bottom || 'auto'};
  width: ${props => props.$width || '100%'};
  height: ${props => props.$height || '100%'};
  z-index: ${props => props.$zIndex || '1'};
`;

/**
 * Section-based parallax effect
 */
export const ParallaxSection = ({ 
  children, 
  strength = 100, 
  direction = 'up', 
  height,
  className,
  style,
  ...props 
}) => {
  const ref = useRef(null);
  // Use Framer Motion's useScroll instead of our custom hook
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"]
  });
  
  // Calculate movement based on direction and strength
  const calculateOutput = () => {
    switch (direction) {
      case 'down':
        return [strength * -1, strength];
      case 'left':
        return [0, strength * -1];
      case 'right':
        return [0, strength];
      case 'up':
      default:
        return [strength, strength * -1];
    }
  };

  const offset = useTransform(
    scrollYProgress,
    [0, 1],
    calculateOutput()
  );

  const getTransformProperty = () => {
    if (direction === 'up' || direction === 'down') {
      return { y: offset };
    }
    return { x: offset };
  };

  return (
    <ParallaxContainer ref={ref} $height={height} className={className} style={style} {...props}>
      <ParallaxItem
        style={getTransformProperty()}
      >
        {children}
      </ParallaxItem>
    </ParallaxContainer>
  );
};

/**
 * Window-based parallax effect that works across the entire viewport
 */
export const GlobalParallax = ({ 
  children, 
  strength = 100, 
  inputRange = [0, 1], 
  outputRange,
  property = 'y',
  isAbsolute = false,
  top,
  left,
  right,
  bottom,
  width,
  height,
  zIndex,
  className,
  style,
  ...props 
}) => {
  const { scrollYProgress } = useScroll();
  
  // If no custom output range is provided, calculate based on strength
  const transformOutput = outputRange || (property === 'y' 
    ? [strength * -1, strength] 
    : property === 'x' 
      ? [strength * -1, strength]
      : [0, 1]
  );

  const motionValue = useTransform(
    scrollYProgress,
    inputRange,
    transformOutput
  );

  // Create dynamic style object based on the property
  const transformStyle = {};
  transformStyle[property] = motionValue;
  
  return (
    <ParallaxItem
      style={{
        ...transformStyle,
        ...style
      }}
      $isAbsolute={isAbsolute}
      $top={top}
      $left={left}
      $right={right}
      $bottom={bottom}
      $width={width}
      $height={height}
      $zIndex={zIndex}
      className={className}
      {...props}
    >
      {children}
    </ParallaxItem>
  );
};

export default ParallaxSection; 